import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@material-ui/core";
import Notification from "../../components/notifications/notfy";

const selectedColor = "#E0E7FF"; //indigo-100

const checkboxSx = {
  color: "#6366F1",
  "&.Mui-checked": {
    color: "#4F46E5",
  },
  "&.MuiCheckbox-indeterminate": {
    color: "#4F46E5",
  },
};

const selectedRowSx = {
  "&.Mui-selected": { background: selectedColor },
  "&.Mui-selected:hover": { background: "#EEF2FF" },
};

// const headCells = bulkHeaderData

// function createData(rows, keys) {
//   const dict = {};
//   keys.map((key, index) => (dict[key] = rows[index]));
//   return dict;
// }

// const keys = headCells.map((cell) => cell.id);

// const rows = rowss.map((rows) => createData(rows+["123123"], keys));

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleDelete, buttonLoading } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: selectedColor,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Results
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          {buttonLoading ? (
            <CircularProgress
              className="mr-2"
              style={{ height: 37, width: 37, color: "#666a75" }}
            />
          ) : (
            <IconButton onClick={() => handleDelete()}>
              <DeleteIcon />
            </IconButton>
          )}
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function StickyHeadTable({
  headCells,
  getTableData,
  deleteData,
  downloadFile,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setBulkData] = useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    const getData = async (doLoad) => {
      if (doLoad) {
        setLoading(true);
      }
      const rows = await getTableData();
      setBulkData(rows);
      if (doLoad) {
        setLoading(false);
      }
    };
    getData(true);
    const updateResults = setInterval(async () => {
      getData(false);
    }, 3000);

    return () => {
      clearInterval(updateResults);
    };
  }, []);

  const handleDownload = async (id) => {
    // const file = await downloadBulkVerifierFile(id);
    const file = await downloadFile(id);
    return file;
  };

  const handleDelete = async () => {
    try {
      setButtonLoading(true);
      setLoading(true);
      // const response = await deleteBulkFinder({ filenames: selected });
      const response = await deleteData({ filenames: selected });
      Notification(response.status, "success");
      setBulkData(rows.filter((row) => !selected.includes(row.name)));
      setSelected([]);
      setButtonLoading(false);
      setLoading(false);
    } catch (ex) {
      setButtonLoading(false);
      setLoading(false);
      if (ex.response && ex.response.status === 401) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification("Something went wrong!", "error");
      return "";
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const filteredRows = rows.filter((n) => n[headCells[1].id]==="100");
      console.log(filteredRows)
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, code) => {
    const selectedIndex = selected.indexOf(code);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleDelete={handleDelete}
        buttonLoading={buttonLoading}
      />
      {loading ? (
        <div className="grid justify-items-center">
          <CircularProgress className="h-6 w-6 my-8" />
        </div>
      ) : (
        <>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      sx={checkboxSx}
                      checked={
                        rows.length > 0 && selected.length === rows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </TableCell>
                  {headCells.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <span className="font-bold"> {column.label}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const completion = row[headCells[1].id];
                    const filename = row[headCells[0].id];

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={selectedRowSx}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            key={row.name}
                            onClick={(event) => handleClick(event, row.name)}
                            sx={checkboxSx}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            disabled={completion !== "100" ? true : false}
                          />
                        </TableCell>
                        {headCells.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id}>
                              {column.id === "download" ? (
                                completion === "100" ? (
                                  <div
                                    className="text-gray-900 cursor-pointer hover:text-indigo-600 hover:underline"
                                    onClick={() => handleDownload(filename)}
                                  >
                                    Download
                                  </div>
                                ) : (
                                  <CircularProgress
                                    className="mr-2"
                                    style={{
                                      height: 18,
                                      width: 18,
                                      color: "#4F46E5",
                                    }}
                                  />
                                )
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
}
