import http from "./httpService";
import { apiUrl } from "../config.json";
const apiEndpoint = apiUrl;

export async function findSinglePhoneNumber(data) {
    console.log(data);
    const { data: response } = await http.post(
        apiEndpoint + "/find-single-phone-number", {...data }
    );
    return response;
}

export async function getSinglePhoneNumberSearches() {
    const { data: response } = await http.get(
        apiEndpoint + "/get-single-phone-number-searches"
    );
    return response;
}

export async function deleteSinglePhoneNumberSearches(data) {
    const { data: response } = await http.post(
        apiEndpoint + "/delete-single-phone-number-searches", {...data }
    );
    return response;
}

export default {
    findSinglePhoneNumber,
    getSinglePhoneNumberSearches,
    deleteSinglePhoneNumberSearches,
};