import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function stopModule(id) {
  const { data: response } = await http.get(`${apiEndpoint}/stop/${id}`);
  return response;
}

export async function runModule(id) {
  const { data: response } = await http.get(`${apiEndpoint}/run/${id}`);
  return response;
}

export async function taskStatus(id) {
  const { data: response } = await http.get(`${apiEndpoint}/task-status/${id}`);
  return response;
}

export async function updateModule(id, data) {
  const { data: response } = await http.post(
    `${apiEndpoint}/update-module-data/${id}`,
    { ...data }
  );
  return response;
}

export async function createModule(id, data) {
  const { data: response } = await http.post(
    `${apiEndpoint}/create-new-module/${id}`,
    { ...data }
  );
  return response;
}

export async function deleteModule(id) {
  const { data: response } = await http.get(
    `${apiEndpoint}/delete-module/${id}`
  );
  return response;
}

export default {
  stopModule,
  runModule,
  taskStatus,
  updateModule,
  createModule,
  deleteModule,
};
