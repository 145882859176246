import http from "./httpService";
import { apiUrl } from "../config.json";
import fileDownload from "js-file-download";

const apiEndpoint = apiUrl;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function downloadFile(fileName) {
    const response = await http.get(`${apiEndpoint}/download-file/${fileName}`, {
        responseType: "blob",
    });
    return fileDownload(response.data, `${fileName}.csv`);

    // return fileDownload(response.data, "results.csv")
}

export async function downloadBulkFinderFile(fileName) {
    const response = await http.get(
        `${apiEndpoint}/download-file/bulk-finder/${fileName}`, {
            responseType: "blob",
        }
    );
    return fileDownload(response.data, `${fileName}.csv`);
}

export async function downloadBulkVerifierFile(fileName) {
    const response = await http.get(
        `${apiEndpoint}/download-file/bulk-verifier/${fileName}`, {
            responseType: "blob",
        }
    );
    return fileDownload(response.data, `${fileName}.csv`);
}

export default {
    downloadFile,
    downloadBulkFinderFile,
    downloadBulkVerifierFile
};