import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl;


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export async function redeemAppsumoLTD(data) {
    const { data: response } = await http.post(`${apiEndpoint}/appsumo-redeem`, {...data });
    return response;
}

export default {
    redeemAppsumoLTD
};