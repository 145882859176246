import React from "react";
import Sidebar from "../../../partials/Sidebar";
import Header from "../../../partials/Header";
import { TitleComponent } from "../../../components/TitleComponent";
import BackdropLoader from "../../../partials/loaders/backdropLoader";
import SelectInput from "../../../partials/inputs/select";
import ModalBasic from "../../../components/ModalBasic";
import Notification from "../../../components/notifications/notfy";
import { CircularProgress } from "@material-ui/core";

// Import the plugins
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { Dashboard } from "@uppy/react";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import StickyHeadTable from "../../../partials/bulkResults/table";
import { bulkHeaderData } from "../../../data/emailOperations/bulkHeader";
import AppsumoBanner from "../../../partials/appsumoBanner";

class BulkFinder extends React.Component {
  state = {
    open: false,
    selectedItems: [],
    sidebarOpen: false,
    screenLoading: false,
    columnMappingModal: false,
    data: {},
    loading: false,
    columns: [],
  };

  setColumnMappingModal = (value) => {
    this.setState({ columnMappingModal: value });
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  afterUpload = async (successfulResult) => {
    const { meta, uploadURL } = successfulResult;
    console.log(meta);
    console.log(uploadURL);
    try {
      const response = await this.props.uploadData({ meta, uploadURL });
      this.setState({
        columns: response.columns,
        data: { filename: response.filename },
      });
      Notification(response.status, "success");
      this.setColumnMappingModal(true);
      this.setState({ screenLoading: false });
    } catch (ex) {
      this.setState({ screenLoading: false });
      if (ex.response && ex.response.status === 406) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const response = await this.props.submitData(this.state.data);
      Notification(response.status, "success");
      this.setColumnMappingModal(false);
      this.setState({ loading: false, data: {} });
      // this.setState({ data: {} });
    } catch (ex) {
      this.setState({ screenLoading: false });
      if (ex.response && ex.response.status === 406) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    const {
      user,
      title,
      type,
      getTableData,
      deleteData,
      userInputs,
      downloadFile,
      children
    } = this.props;
    console.log(userInputs);
    const { sidebarOpen, screenLoading, columnMappingModal, columns, loading } =
      this.state;

    // const userInputs = [
    //   { label: "First Name", name: "first_name" },
    //   { label: "Last Name", name: "last_name" },
    //   { label: "Domain", name: "domain_name" },
    // ];

    const uppy = new Uppy({
      restrictions: {
        maxFileSize: 100000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".csv"],
      },
    }).use(Tus, { id: "123", endpoint: "https://tusd.tusdemo.net/files/" });

    uppy.on("complete", (result) => {
      console.log("successful files:", result.successful);
      if (result.successful.length === 1) {
        this.setState({ screenLoading: true });
        this.afterUpload(result.successful[0]);
      }
    });

    return (
      <>
        {/* <AppsumoBanner /> */}
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />

            {children}

            <main>
              <BackdropLoader
                open={screenLoading}
                text="Processing your file...."
              />
              <TitleComponent title={`${title} | Data Chroma`} />
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      {type} ✨
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  <div className="flex flex-col col-span-full sm:col-span-6 md:col-span-6 xl:col-span-4 ">
                    <Dashboard
                      className="shadow-lg z-10"
                      uppy={uppy}
                      height={400}
                      // width={350}
                      proudlyDisplayPoweredByUppy={false}
                      theme="light"
                    />
                  </div>
                  <div className="flex flex-col col-span-full sm:col-span-6 md:col-span-6 xl:col-span-8">
                    <StickyHeadTable
                      headCells={bulkHeaderData}
                      getTableData={getTableData}
                      deleteData={deleteData}
                      downloadFile={downloadFile}
                    />
                    {/* <BulkTable selectedItems={this.handleSelectedItems} /> */}
                  </div>
                </div>
              </div>
              <ModalBasic
                id="column-mapping-modal"
                modalOpen={columnMappingModal}
                setModalOpen={this.setColumnMappingModal}
                title={`Column Mapping`}
              >
                {/* Modal content */}
                <form onSubmit={this.handleSubmit}>
                  <div className="px-5 py-4">
                    <div className="text-sm">
                      <div className="font-medium text-gray-800 mb-3">
                        Map your columns to their field 🙌
                      </div>
                    </div>
                    <div className="space-y-3">
                      <div>
                        {userInputs.map((input, index) => (
                          <SelectInput
                            options={columns}
                            onChange={this.handleChange}
                            {...input}
                            required
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="px-5 py-4 border-t border-gray-200">
                    <div className="flex flex-wrap justify-center space-x-2">
                      <button
                        className="btn-sm text-white rounded-lg shadow-brandShadow py-1.5 px-3 disabled:cursor-not-allowed disabled:bg-indigo-400 bg-indigo-600 hover:bg-indigo-800"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            Adding &nbsp;&nbsp;
                            <CircularProgress
                              className="mt-1"
                              style={{ width: 13, height: 13, color: "#fff" }}
                            />
                          </>
                        ) : (
                          <>Bulk Find -&gt;</>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </ModalBasic>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default BulkFinder;
