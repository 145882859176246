import React, { Component } from "react";
import { TitleComponent } from "../../components/TitleComponent";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Notification from "../../components/notifications/notfy";
import BackdropLoader from "../../partials/loaders/backdropLoader";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ModalBasic from "../../components/ModalBasic";

// Services
import moduleOperations from "../../services/moduleOperations";
import getModuleData from "../../services/getModuleData";
import downloadFile from "../../services/downloadFile";

// Icons
import { PlayIcon, StopIcon, CogIcon, DuplicateIcon } from "@heroicons/react/outline";
import AppsumoBanner from "../../partials/appsumoBanner";

function NewlineText(props) {
  const text = props.text;
  return (
    <div className="text-sm" style={{ whiteSpace: "pre-wrap" }}>
      {text}
    </div>
  );
}

const run = <PlayIcon className="mr-2 h-5 w-5" />;
const stop = <StopIcon className="mr-2 h-5 w-5" fontSize="small" />;

class SingleModule extends Component {
  state = {
    data: {},
    sidebarOpen: false,
    screenLoading: false,
    statusRequestInterval: "",
    logsModal: false,
    modalData: [],
    runLoading: false,
  };

  async componentDidMount() {
    this.setState({ screenLoading: true });
    try {
      const data = await getModuleData.getModuleData(
        this.props.match.params.id
      );
      this.setState({ ...data });
      this.taskScheduler();
      this.taskStatus();
    } catch (e) {
      Notification("Some error. Please try Again in some time", "error");
    }
    this.setState({ screenLoading: false });
  }

  componentWillUnmount() {
    console.log("Scraper Unmount");
    this.myStopFunction();
  }

  taskStatus = async () => {
    const { id } = this.state.data;
    const response = await moduleOperations.taskStatus(id);

    this.setState({ logs: response.logs });

    console.log(response);

    if (
      response.status.toLowerCase() === "SUCCESS".toLowerCase() ||
      response.status.toLowerCase() === "FAILURE".toLowerCase() ||
      response.status.toLowerCase() === "NOT STARTED".toLowerCase() ||
      response.status.toLowerCase() === "REVOKED".toLowerCase()
    ) {
      this.myStopFunction();
      const { celery_data } = this.state;
      celery_data.internal_status = "Stopped";
      this.setState({ celery_data });
    }
  };

  taskScheduler = () => {
    const statusRequestInterval = setInterval(this.taskStatus.bind(this), 3000);
    this.setState({ statusRequestInterval });
  };

  myStopFunction = () => {
    clearInterval(this.state.statusRequestInterval);
  };

  handleRun = async (e) => {
    console.log("Run clicked");
    this.setState({ runLoading: true });
    e.preventDefault();
    const { id } = this.state.data;

    try {
      await moduleOperations.runModule(id);
    } catch (ex) {
      this.setState({ runLoading: false });
      if (ex.response && ex.response.status === 301) {
        Notification(ex.response.status, "error");
      } else {
        Notification("Something went wrong! Please Try again Later.", "error");
        return "";
      }
    }
    this.setState({ runLoading: false });
    const { celery_data } = this.state;
    celery_data.internal_status = "Running";
    this.setState({ celery_data });
    this.taskScheduler();
  };

  handleStop = async (e) => {
    console.log("Stop clicked");
    e.preventDefault();
    const { id } = this.state.data;
    try {
      const response = await moduleOperations.stopModule(id);
      Notification(response.status, "success");
    } catch (ex) {
      if (ex.response && ex.response.status === 300) {
        Notification(ex.response.status, "error");
      } else {
        Notification("Something went wrong! Please Try again Later.", "error");
      }
    }
    this.myStopFunction();
    const { celery_data } = this.state;
    celery_data.internal_status = "Stopped";
    this.setState({ celery_data });
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  textType = (type) => {
    switch (type) {
      case "SUCCESS":
        return "Run";
      case "REVOKED":
        return "Run";
      case "Stopped":
        return "Run";
      default:
        return "Stop";
    }
  };
  buttonColor = (type) => {
    switch (type) {
      case "SUCCESS":
        return "bg-green-500 hover:bg-green-600";
      case "REVOKED":
        return "bg-green-500 hover:bg-green-600";
      case "Stopped":
        return "bg-green-500 hover:bg-green-600";
      default:
        return "bg-red-500 hover:bg-red-600";
    }
  };
  svgType = (type) => {
    switch (type) {
      case "SUCCESS":
        return run;
      case "REVOKED":
        return run;
      case "Stopped":
        return run;
      default:
        return stop;
    }
  };
  clickEvent = (type) => {
    switch (type) {
      case "SUCCESS":
        return this.handleRun;
      case "REVOKED":
        return this.handleRun;
      case "Stopped":
        return this.handleRun;
      default:
        return this.handleStop;
    }
  };

  handleDownload = async () => {
    const { id } = this.state.data;
    const file = await downloadFile.downloadFile(id);
    return file;
  };

  setModalData = (index) => {
    this.setState({ modalData: this.state.old_logs[index] });
    this.setLogsModal();
  };

  setLogsModal = () => {
    this.setState({ logsModal: !this.state.logsModal });
  };

  render() {
    const { id } = this.props.match.params;
    const { user } = this.props;

    const {
      sidebarOpen,
      screenLoading,
      data,
      old_logs,
      status,
      logs,
      celery_data,
      logsModal,
      modalData,
      runLoading,
    } = this.state;

    return (
      <>
        {/* <AppsumoBanner /> */}

        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            <main>
              <TitleComponent title={`${id} | Data Chroma`} />
              {screenLoading ? (
                <BackdropLoader open={screenLoading} text="Loading..." />
              ) : (
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col self-start">
                      <h1 className="text-2xl font-bold self-start text-gray-900 mb-1">
                        {id}
                      </h1>
                      <h1 className="text-sm font-medium self-start text-gray-400 mb-3">
                        {data?.sub_category}
                      </h1>
                    </div>
                    <div className="grid place-items-end self-center">
                    <div className="grid grid-flow-col">
                      <Link
                          className="mr-4 px-4 py-2 btn-xs self-center text-white bg-teal-500 hover:bg-teal-600"
                          to={`/config/${data?.category}/${data?.sub_category}/${data?.form_type}?name=${id}`}
                        >
                          Duplicate Module
                          <DuplicateIcon className="ml-2 h-5 w-5" />
                        </Link>
                        <Link
                          className="px-4 py-2 btn-xs self-center text-white bg-indigo-500 hover:bg-indigo-600"
                          to={`/config/${data?.category}/${data?.sub_category}/${data?.form_type}/${id}`}
                        >
                          Re-Configure
                          <CogIcon className="ml-2 h-5 w-5" />
                        </Link>
                    </div>
                    </div>
                    {/* <div className="flex flex-col self-end">
                    <h1 className="text-2xl font-bold text-gray-900 mb-1">{id}</h1>
                    <h1 className="text-sm font-medium text-gray-400 mb-3">
                      {data?.sub_category}
                    </h1>
                  </div> */}
                  </div>

                  <div className="grid grid-cols-18 gap-x-2 gap-y-2">
                    <div className="col-span-full sm:col-span-10 md:col-span-10 lg:col-span-10 tablet:col-span-10 xl:col-span-10 place-self-start w-full">
                      <div className="bg-white shadow-lg rounded-lg border border-gray-200 pb-3 ">
                        <div className="flex flex-row px-5 border-b bg-gray-50 py-2 rounded-t-lg justify-between">
                          <h2 className="font-semibold text-gray-800 text-sm py-1 self-start content-center">
                            Logs
                          </h2>
                          <button
                            className={`btn-xs text-white ${this.buttonColor(
                              celery_data?.internal_status
                            )}`}
                            onClick={this.clickEvent(
                              celery_data?.internal_status
                            )}
                          >
                            {runLoading ? (
                              <>
                                <CircularProgress
                                  style={{
                                    width: 13,
                                    height: 13,
                                    color: "#fff",
                                  }}
                                />
                                &nbsp;&nbsp;&nbsp;Wait..
                              </>
                            ) : (
                              <>
                                {this.svgType(celery_data?.internal_status)}
                                <h1 className="text-xs">
                                  {this.textType(celery_data?.internal_status)}
                                </h1>
                              </>
                            )}
                          </button>
                        </div>
                        <div className="flex flex-col px-5 mb-1">
                          <div className="flex-grow mt-2 h-auto max-h-96 overflow-auto">
                            <NewlineText text={logs} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full sm:col-span-8 md:col-span-8 lg:col-span-8 tablet:col-span-8 xl:col-span-8 place-self-start w-full">
                      <div className="grid grid-cols-1 grid-rows-1 gap-y-4">
                        <div className="bg-white shadow-lg rounded-lg border border-gray-200 pb-3 ">
                          <div className="flex flex-col px-5 border-b bg-gray-50 pt-2 rounded-t-lg justify-between">
                            <h2 className="font-semibold text-gray-800 text-sm self-start content-center">
                              Results
                            </h2>
                            <h2 className="font-semibold text-gray-400 text-xs self-start content-center">
                              Please click ‘Download Result’ only when the
                              module has finished.
                            </h2>
                          </div>
                          <div className="flex flex-col px-5 mb-1">
                            <div className="flex mt-2 justify-center">
                              {status ? (
                                <h1
                                  className="mt-4 text-sm font-medium text-gray-900 cursor-pointer hover:text-indigo-600 hover:underline"
                                  onClick={this.handleDownload}
                                >
                                  Download Result
                                </h1>
                              ) : (
                                <h1 className="text-sm text-gray-900">
                                  No Results!
                                </h1>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="bg-white shadow-lg rounded-lg border border-gray-200 pb-3">
                          <div className="flex flex-col px-5 border-b bg-gray-50 py-2 rounded-t-lg justify-between">
                            <h2 className="font-semibold text-gray-800 text-sm self-start content-center">
                              Previous Runs
                            </h2>
                          </div>
                          <div className="flex flex-col px-5 mb-1">
                            <div className="divide-y mt-2 divide-indigo-400">
                              {old_logs?.length === 0 ? (
                                <h1 className="text-sm text-gray-900">
                                  No Previous Runs!
                                </h1>
                              ) : (
                                old_logs?.map((row, index) => (
                                  <div className="mb-2" key={index}>
                                    {/* <li> */}
                                    <h1 className="text-sm text-indigo-800 font-semibold mt-1">
                                      Run {row[3]}
                                    </h1>
                                    <h1
                                      className="text-xs text-gray-900 hover:text-indigo-600 hover:underline font-medium cursor-pointer mt-1"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setModalData(index);
                                      }}
                                    >
                                      Click to View Complete Logs.
                                    </h1>
                                    {/* End */}
                                  </div>
                                  // </ul>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <ModalBasic
                id="feedback-modal"
                modalOpen={logsModal}
                setModalOpen={this.setLogsModal}
                title={`Run: ${modalData[3]} Logs.`}
              >
                {/* Modal content */}
                <div className="px-5 py-4 h-auto max-h-96 overflow-auto">
                  <div className="space-y-3">
                    <NewlineText text={modalData[2]} />
                  </div>
                </div>
                {/* Modal footer */}
                <div className="px-5 py-4 border-t border-gray-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm  rounded-lg shadow-brandShadow py-1.5 px-3 border border-indigo-600 text-indigo-600"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setLogsModal();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </ModalBasic>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default SingleModule;
