import React from "react";
import { Route, Redirect } from "react-router-dom";
import splitbee from "@splitbee/web";

// Services
import auth from "../services/authService";

// This initiliazes Splitbee.js
var tracking = false;
console.log(process.env);
if (process.env.REACT_APP_VERCEL_ENV === "production") {
  splitbee.init({
    // To use Splitbee on another subdomain.
    // Token can be found in project settings
    token: "7QJJAL050QXB",

    // Enable cookie-less mode. Defaults to `false`
    // disableCookie: false,

    // Set custom urls when using proxying
    scriptUrl: "https://bitter-dream-8dbf.datachroma.workers.dev/sb.js",
    apiUrl: "https://hive.splitbee.io",
  });
  tracking = true;
  console.log("Tracking Enabled");
} else {
  console.log("Tracking Disabled");
}

// Enable cookie based tracking
splitbee.enableCookie();

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      exact
      path={path}
      {...rest}
      render={(props) => {
        if (!auth.isUserLoggedIn())
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location },
              }}
            />
          );
        // Attach data to the current user
        {
          tracking ? (
            <>
              {splitbee.user.set({
                email: auth.isUserLoggedIn().email,
                displayName: auth.isUserLoggedIn().name,
              })}
              {console.log("User setted")}
              {console.log(auth.isUserLoggedIn().email)}
            </>
          ) : (
            console.log("Tracking Disabled")
          );
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
