import React, { useState } from "react";
import ModalBasic from "./components/ModalBasic";

const YouTubeBanner = ({ text, link, buttonCTA }) => {
  const [basicModalOpen, setBasicModalOpen] = useState(false);

  return (
    <>
      <div className="flex justify-center bg-banner py-3">
        <div className="flex px-4 py-2 rounded-sm text-sm">
          <div className="flex text-black text-center">
            <span>{text}</span>
          </div>
        </div>
        {/* <a href={link} target="_blank"> */}
        <div
          className="flex px-4 py-2 rounded-md text-sm bg-white shadow-lg border border-gray-200 text-gray-600 cursor-pointer"
          aria-controls="basic-modal"
          onClick={(e) => {
            e.stopPropagation();
            setBasicModalOpen(true);
          }}
        >
          <div className="flex w-full justify-between items-start">
            {/* <div className="flex"> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              className="flex-shrink-0 fill-current text-red-500 mr-3"
            >
              <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
            </svg>
            <div>{buttonCTA}</div>
          </div>
        </div>
        {/* </a> */}
      </div>

      <ModalBasic
        id="basic-modal"
        modalOpen={basicModalOpen}
        setModalOpen={setBasicModalOpen}
        title={text}
        className="w-full max-w-md mx-auto"
      >
        {/* Modal content */}
        <div className="px-5 py-4">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
          title={text}
            src={link}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
        </div>
      </ModalBasic>
    </>
  );
};

export default YouTubeBanner;
