import React, { useState } from "react";
import ModalBasic from "../components/ModalBasic";
import CircularProgress from "@material-ui/core/CircularProgress";
import linkedinAccounts from "../services/linkedinAccounts";
import Notification from "../components/notifications/notfy";

function ConnectedAccountsCard({ connectedAccounts, getConnectedAccounts }) {
  const [modifyAccountForm, setmodifyAccountForm] = useState(connectedAccounts);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const handleDelete = async (e) => {
    setRemoveLoading(true);
    e.preventDefault();
    console.log("Delete", connectedAccounts.account_name);
    try {
      const response = await linkedinAccounts.disconnectAccount(
        connectedAccounts.account_name
      );
      Notification(
        `${connectedAccounts.account_name} ${response.status}`,
        "success"
      );
    } catch (ex) {
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
    }
    getConnectedAccounts();
  };

  const handleChange = ({ currentTarget: input }) => {
    modifyAccountForm[input.name] = input.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Modify submit");
    setLoading(true);
    console.log(modifyAccountForm);
    // this.setState({ modalbutton_loading: true })
    try {
      const response = await linkedinAccounts.modifyAccount(modifyAccountForm);
      Notification(response.status, "success");
      setLoading(false);
      setmodifyAccountForm(connectedAccounts);
      setFeedbackModalOpen(false);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        Notification(ex.response.data.status, "error");
        setLoading(false);
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
      setLoading(false);
    }
    getConnectedAccounts();
    e.preventDefault();
    console.log("Clicked Save!");
  };

  const typeColor = (type) => {
    switch (type) {
      case "Active":
        return "bg-green-100 text-green-600";
      case "Expired":
        return "bg-red-100 text-red-600";
      default:
        return "bg-green-100 text-green-600";
    }
  };

  return (
    <div className="flex flex-col col-span-8 sm:col-span-full md:col-span-6 lg:col-span-8 xl:col-span-6 2xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 place-self-start w-full">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2"></header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">
          {connectedAccounts.account_name}
        </h2>
        <div className="flex items-start mt-4">
          <div
            className={`text-xs font-semibold text-white px-2.5 py-0.5 rounded-full ${typeColor(
              connectedAccounts.status
            )}`}
          >
            {connectedAccounts.status}
          </div>
        </div>
        {/* </Link> */}
        <footer className="mt-4 mb-4">
          <div className="flex justify-between items-center">
            <div>
              {/* Start */}
              <button
                className="text-sm inline-flex font-medium rounded-lg text-center px-6 py-1 border border-indigo-600 text-indigo-600 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setFeedbackModalOpen(true);
                }}
              >
                Edit
              </button>
              <ModalBasic
                id="feedback-modal"
                modalOpen={feedbackModalOpen}
                setModalOpen={setFeedbackModalOpen}
                title={`Modify Session Cookie for: ${connectedAccounts.account_name}`}
              >
                {/* Modal content */}
                <form onSubmit={handleSubmit}>
                  <div className="px-5 py-4">
                    {/* <div className="text-sm">
                      <div className="font-medium text-gray-800 mb-3">
                        Let us know what you think 🙌
                      </div>
                    </div> */}
                    <div className="space-y-3">
                      <div className="cursor-not-allowed">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="account_name"
                        >
                          Account Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          id="account_name"
                          className="form-input w-full px-2 py-1 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                          type="text"
                          defaultValue={`${connectedAccounts.account_name}`}
                          disabled
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="li_at"
                          onChange={handleChange}
                        >
                          LinkedIn session Cookie (li_at)
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          id="li_at"
                          className="form-input w-full px-2 py-1"
                          defaultValue={`${connectedAccounts.li_at}`}
                          onChange={handleChange}
                          name="li_at"
                          type="li_at"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="px-5 py-4 border-t border-gray-200">
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm  rounded-lg shadow-brandShadow py-1.5 px-3 border border-indigo-600 text-indigo-600"
                        onClick={(e) => {
                          e.preventDefault();
                          setFeedbackModalOpen(false);
                        }}
                      >
                        Close
                      </button>
                      <button
                        className="btn-sm text-white rounded-lg shadow-brandShadow py-1.5 px-3 disabled:cursor-not-allowed disabled:bg-indigo-400 bg-indigo-600 hover:bg-indigo-800"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            Verifying Cookie &nbsp;&nbsp;&nbsp;
                            <CircularProgress
                              className="mt-1"
                              style={{ width: 13, height: 13, color: "#fff" }}
                            />
                          </>
                        ) : (
                          "Verify and Modify \u2192"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </ModalBasic>
              {/* End */}
            </div>
            <div>
              <button
                className="flex text-sm font-medium text-red-400 hover:text-red-600 disabled:cursor-not-allowed"
                onClick={handleDelete}
                disabled={removeLoading}
              >
                {removeLoading ? (
                  <div className="flex-grow mr-8">
                    <CircularProgress
                      className="mt-1"
                      style={{ width: 20, height: 20, color: "#F87171" }}
                    />
                  </div>
                ) : (
                  <>Remove -&gt;</>
                )}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ConnectedAccountsCard;
