import React, { Component } from "react";
import { TitleComponent } from "../../components/TitleComponent";
import SingleForm from "../../partials/single/singleForm";
import linkedinAccounts from "../../services/linkedinAccounts";
import Notification from "../../components/notifications/notfy";
import ConnectedAccountsCard from "../../partials/connectedAccountCard";
import BackdropLoader from "../../partials/loaders/backdropLoader";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import AppsumoBanner from "../../partials/appsumoBanner";
import Banner from "../../partials/youTubeBanner";

class LinkedinAccount extends Component {
  state = {
    sidebarOpen: false,
    loading: false,
    screenLoading: false,
    connectedAccounts: [],
    addAccountForm: {
      account_name: "",
      session_cookie: "",
    },
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  componentDidMount() {
    this.getConnectedAccounts();
  }

  handleChange = ({ currentTarget: input }) => {
    const { addAccountForm } = this.state;
    addAccountForm[input.name] = input.value;
    this.setState({ addAccountForm });
  };

  getConnectedAccounts = async () => {
    this.setState({ screenLoading: true });
    try {
      const response = await linkedinAccounts.getConnectedAccounts();
      this.setState({ connectedAccounts: response.accounts });
      console.log(Object.keys(response.accounts).length);
    } catch (ex) {
      Notification(
        "Something went wrong! Could not get your connected accounts.",
        "error"
      );
      console.log("Some error in getting connected accounts.");
    }
    this.setState({ screenLoading: false });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const response = await linkedinAccounts.addNewAccount(
        this.state.addAccountForm
      );
      Notification(response.status, "success");
      this.setState({ loading: false, addAccountForm: {} });
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        Notification(ex.response.data.status, "error");
        this.setState({ loading: false });
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
      this.setState({ loading: false });
    }
    this.getConnectedAccounts();
  };

  render() {
    const { user, title, type } = this.props;
    const { connectedAccounts, screenLoading, loading, sidebarOpen } =
      this.state;
    return (
      <>
        {/* <AppsumoBanner /> */}

        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            <Banner text="Watch a short tutorial on how to add a LinkedIn Account" link="https://www.youtube.com/embed/aMqnXoA0YZY" buttonCTA="Watch (2 min)."/>
            <main>
              <TitleComponent title={`${title} | Data Chroma`} />
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      {type} ✨
                    </h1>
                  </div>
                </div>

                <div className="grid grid-cols-18 gap-6">
                  <SingleForm
                    {...this.props}
                    formTitle={type}
                    onSubmit={this.handleSubmit}
                    onChange={this.handleChange}
                    loading={loading}
                  />
                  {screenLoading ? (
                    <BackdropLoader
                      open={screenLoading}
                      text="Loading your connected LinkedIn Accounts..."
                    />
                  ) : // <LottieLoader />
                  connectedAccounts.length === 0 ? (
                    <>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/aMqnXoA0YZY"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </>
                  ) : (
                    <div className="col-span-full sm:col-span-8 md:col-span-12 lg:col-span-10 xl:col-span-12 grid grid-cols-12 gap-6">
                      {connectedAccounts.map((data, index) => (
                        <ConnectedAccountsCard
                          key={index}
                          connectedAccounts={data}
                          getConnectedAccounts={this.getConnectedAccounts}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default LinkedinAccount;
