import React, { Component } from "react";
import {useLocation} from "react-router-dom";
import { TitleComponent } from "../../components/TitleComponent";
import Header from "../../partials/Header";
import TextInput from "../../partials/inputs/text";
import Sidebar from "../../partials/Sidebar";
import Notification from "../../components/notifications/notfy";
import { CircularProgress } from "@mui/material";
import LiatInput from "../../partials/inputs/liatSelect";
import SelectInput from "../../partials/inputs/select";

// Data
import CategoryData from "../../data/modulesData/category.json";
import { ConfigForms } from "../../data/formData/configForms";

// Services
import linkedinAccounts from "../../services/linkedinAccounts";
import getModuleData from "../../services/getModuleData";
import moduleOperations from "../../services/moduleOperations";
import BackdropLoader from "../../partials/loaders/backdropLoader";
import AppsumoBanner from "../../partials/appsumoBanner";

class Config extends Component {
  state = {
    sidebarOpen: false,
    connectedAccounts: [],
    loading: false,
    data: {},
    screenLoading: false,
  };

  async componentDidMount() {
    const moduleName = this.props.match.params.moduleName;
    try {
      if (moduleName) {
        this.setState({ screenLoading: true });
        const data = await getModuleData.getModuleData(moduleName);
        this.setState({ ...data });
        this.setState({ screenLoading: false });
      }
    } catch (ex) {
      this.setState({ screenLoading: false });
      Notification(
        "Something went wrong! Please try again after some time.",
        "error"
      );
      return this.props.history.push(`/module/${moduleName}`);
    }

    try {
      const response = await linkedinAccounts.getConnectedAccounts();
      this.setState({ connectedAccounts: response.accounts });
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        Notification(ex.response.data.status, "error");
        this.setState({ loading: false });
        return "";
      }
      Notification(
        "Something went wrong! Could not get your connected accounts.",
        "error"
      );
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    console.log("Submit");
    this.setState({ loading: true });
    e.preventDefault();
    const data = this.state.data;
    const { id } = this.state.data;
    const { category, subCategory, formType, moduleName } =
      this.props.match.params;
    data.form_type = formType;
    data.category = category;
    data.sub_category = subCategory;
    this.setState({ data });
    // return "";
    try {
      if (moduleName) {
        const response = await moduleOperations.updateModule(
          id,
          this.state.data
        );
        // Notification("Module Re-Configured!", "success");
        Notification(response.status, "success");
      } else {
        const response = await moduleOperations.createModule(
          id,
          this.state.data
        );
        Notification(response.status, "success");
      }
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response && ex.response.status === 401) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification(
        "Something went wrong! Could not create a new module",
        "error"
      );
      return "";
    }

    this.setState({ loading: false });
    return this.props.history.push(`/module/${id}`);
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  render() {
    // const [searchParams, setSearchParams] = useSearchParams();
    const search = this.props.location.search;
    const name = new URLSearchParams(search).get('name')
    const deafultModuleName = name + " - 1";
    console.log("----->", deafultModuleName)

    // const search = useLocation().search;

    const { category, subCategory, moduleName } = this.props.match.params;
    const { user, title } = this.props;
    const { sidebarOpen, connectedAccounts, data, loading, screenLoading } =
      this.state;
    const moduleData = CategoryData[category]["subCategories"][subCategory];

    return (
      <>
        {/* <AppsumoBanner /> */}

        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            <main>
              <TitleComponent title={`${title} | Data Chroma`} />

              <BackdropLoader open={screenLoading} text="Loading..." />

              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      Configure Your Module ✨
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12 sm:col-span-12 md:col-span-8 lg:col-span-8 tablet:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200 place-self-start w-full">
                    <div className="flex flex-col px-5 border-b bg-gray-50 py-2 rounded-t-lg">
                      <h2 className="font-semibold text-gray-800 text-sm py-1">
                        {subCategory}
                      </h2>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="flex flex-col px-5 mb-4">
                        <div className="flex-grow mt-2">
                          <TextInput
                            name="id"
                            label="Module Name"
                            value={moduleName}
                            placeholder={name===null ? "Test 101" : deafultModuleName}
                            onChange={this.handleChange}
                            required
                          />
                          {moduleData.formFields["li_at"] ? (
                            <LiatInput
                              name="li_at"
                              label="Select LinkedIn Account to use"
                              options={connectedAccounts}
                              value={data?.li_at}
                              onChange={this.handleChange}
                              required
                              // handleChange={this.handleChange}
                            />
                          ) : (
                            <></>
                          )}
                          {moduleData.formInputs.map((input, index) => (
                            <TextInput
                              key={index}
                              name={ConfigForms[input]["name"]}
                              label={
                                input === "limit"
                                  ? moduleData.formFields.limit.label
                                  : ConfigForms[input]["label"]
                              }
                              value={
                                data
                                  ? data[ConfigForms[input]["name"]]
                                  : undefined
                              }
                              onChange={this.handleChange}
                              placeholder={ConfigForms[input]["placeholder"]}
                              required
                              type={input === "limit" ? "number" : "text"}
                              />
                          ))}
                          {moduleData.formFields.enrichWorkEmail ? (
                            <SelectInput
                              {...ConfigForms["enrichWorkEmail"]}
                              onChange={this.handleChange}
                              value={
                                data[ConfigForms["enrichWorkEmail"]["name"]]
                              }
                              required
                            />
                          ) : (
                            <></>
                          )}
                          <TextInput
                            name={ConfigForms["emailResults"]["name"]}
                            label={ConfigForms["emailResults"]["label"]}
                            value={user.email}
                            placeholder={
                              ConfigForms["emailResults"]["placeholder"]
                            }
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <footer className="mt-3 grid">
                          <div className="justify-self-center">
                            <button
                              className="text-sm inline-flex font-medium rounded-lg text-center px-2.5 py-1 border text-white shadow-brandShadow disabled:cursor-not-allowed disabled:border-indigo-400 disabled:bg-indigo-400 border-indigo-600 bg-indigo-600"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  {moduleName ? (
                                    <>
                                      Re-configuring Module &nbsp;&nbsp;&nbsp;
                                    </>
                                  ) : (
                                    <>Creating New Module &nbsp;&nbsp;&nbsp;</>
                                  )}
                                  <CircularProgress
                                    className="mt-1"
                                    style={{
                                      width: 13,
                                      height: 13,
                                      color: "#fff",
                                    }}
                                  />
                                </>
                              ) : moduleName ? (
                                <>Re-configure Module -&gt;</>
                              ) : (
                                <>Create Module -&gt;</>
                              )}
                            </button>
                          </div>
                        </footer>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Config;
