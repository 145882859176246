import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, Component } from "react";

// Import pages
import Dashboard from "../pages/Dashboard";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import ResetPassword from "../pages/ResetPassword";
import Logout from "../pages/logout";
import Modules from "../pages/modules/Modules";
import SingleFinder from "../pages/emailOperations/emailFinder/singleFinder";
import BulkFinder from "../pages/emailOperations/emailFinder/bulkFinder";
import LinkedinAccount from "../pages/linkedinAccounts/linkedinAccounts";
import ProtectedRoute from "../components/protectedRoute";
import Account from "../pages/settings/Account";
import Plans from "../pages/settings/Plans";
import PageNotFound from "../pages/utility/PageNotFound";
import ManageSubscription from "../pages/settings/manageSubscription";
import SingleModule from "../pages/modules/singleModule";
import Description from "../pages/modules/description";
import Config from "../pages/modules/config";
import EmailCredits from "../pages/settings/emailCredits";
import AppsumoLTD from "../pages/appsumoLTD";
import Banner from "../partials/youTubeBanner";

// Utils
import {
  getBulkFinderResults,
  getBulkVerifierResults,
  getSingleFinderResults,
  getSingleVerifierResults,
} from "../utils/getEmailSingleResults";
import { getSinglePhoneFinderResults } from "../utils/getSinglePhoneResults";

// Services
import auth from "../services/authService";
import {
  downloadBulkFinderFile,
  downloadBulkVerifierFile,
} from "../services/downloadFile";
import {
  deleteSinglePhoneNumberSearches,
  findSinglePhoneNumber,
} from "../services/phoneNumberOperations";

import {
  deleteBulkFinder,
  deleteBulkVerifier,
  deleteSingleFinderSearches,
  deleteSingleVerifierSearches,
  findSingleEmail,
  uploadBulkFinder,
  uploadBulkFinder2,
  uploadBulkVerifier,
  uploadBulkVerifier2,
  verifySingleEmail,
} from "../services/emailOperations";

// Data
import { SingleFinderFormData } from "../data/formData/singleFinderData";
import { SingleVerifierFormData } from "../data/formData/singleVerifierData";
import { addLinkedinAccountFormData } from "../data/formData/addLinkedinAccountData";
import { singleFinderHeaderData } from "../data/emailOperations/singleFinderHeader";
import { singleVerifierHeaderData } from "../data/emailOperations/singleVerifierHeader";
import { verifierUserInputs } from "../data/emailOperations/veriferUserInputs";
import { finderUserInputs } from "../data/emailOperations/finderUserInputs";
import { appsumoLtdData } from "../data/formData/appsumoLtdData";
import { SinglePhoneNumberFormData } from "../data/formData/singlePhoneNumberData";
import { singlePhoneNumberFinderHeaderData } from "../data/phoneNumberOperations/singlePhoneNumberFinderHeader";

class Router extends Component {
  state = {
    user: auth.isUserLoggedIn(),
    sidebarOpen: false,
  };

  updateUser = () => {
    const user = auth.isUserLoggedIn();
    this.setState({ user });
  };

  setSidebarOpen = () => {
    var sidebarOpen = this.state.sidebarOpen;
    sidebarOpen = !sidebarOpen;
    this.setState({ sidebarOpen });
  };

  render() {
    // const [sidebarOpen, setSidebarOpen] = useState(false);

    const { user } = this.state;
    console.log(user);
    // window.drift.SNIPPET_VERSION = '0.3.1';
    // window.drift.load('caahsnan8zti');
    // window.drift.on('ready', function() {
    //     window.drift.api.setUserAttributes({
    //         email: user?.email,
    //         name: user?.name
    //     })
    // })

    return (
      <>
        <Suspense>
          <Switch>
            <Redirect exact from="/" to="/signin" />
            <Route exact path="/signin">
              <Signin user={user} updateUser={this.updateUser} title="Signin" />
            </Route>
            <Route exact path="/signup">
              <Signup user={user} updateUser={this.updateUser} title="Signup" />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route exact path="/logout">
              <Logout user={user} updateUser={this.updateUser} />
            </Route>
            {/* Dashboard */}
            {/* <Drift
              appId="caahsnan8zti"
              attributes={{ email: "user@example.com", company: "Acme Inc" }}
            /> */}
            <ProtectedRoute
              exact
              path="/dashboard"
              component={(props) => (
                <Dashboard user={user} title="Dashboard" {...props} />
              )}
            />
            <ProtectedRoute
              exact
              path="/module/:id"
              component={(props) => <SingleModule user={user} {...props} />}
            />
            {/* LinkedIn Automations */}
            <ProtectedRoute
              exact
              path="/prospect"
              component={(props) => (
                <Modules
                  category="Prospect"
                  user={user}
                  title="Prospect"
                  {...props}
                />
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/enrich"
              component={(props) => (
                <Modules
                  category="Enrich"
                  user={user}
                  title="Enrich"
                  {...props}
                />
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              path="/description/:category/:subCategory/:formType"
              component={(props) => <Description {...props} user={user} />}
            />
            <ProtectedRoute
              path="/config/:category/:subCategory/:formType/:moduleName?"
              component={(props) => (
                <Config {...props} user={user} title="Config" />
              )}
            />
            <ProtectedRoute
              exact
              path="/add-linkedin-account"
              component={(props) => (
                <LinkedinAccount
                  type="Add a LinkedIn Account"
                  user={user}
                  title="Add LinkedIn Account"
                  {...addLinkedinAccountFormData}
                  {...props}
                />
              )}
            ></ProtectedRoute>
            {/* Tools */}
            <ProtectedRoute
              exact
              path="/email-finder/single-search"
              component={(props) => (
                <SingleFinder
                  type="Single Search Email Finder"
                  user={user}
                  title="Single Search"
                  data={SingleFinderFormData}
                  getTableData={getSingleFinderResults}
                  headCells={singleFinderHeaderData}
                  deleteData={deleteSingleFinderSearches}
                  formSubmit={findSingleEmail}
                  {...props}
                  >
                  <Banner
                    text="Watch a short tutorial on how to use our single email finder"
                    link="https://www.youtube.com/embed/tFnRY5Rh7ss"
                    buttonCTA="Watch (2 min)."
                  />
                </SingleFinder>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/email-finder/bulk-upload"
              component={(props) => (
                <BulkFinder
                  type="Bulk Upload Email Finder"
                  user={user}
                  title="Bulk Finder"
                  getTableData={getBulkFinderResults}
                  userInputs={finderUserInputs}
                  deleteData={deleteBulkFinder}
                  uploadData={uploadBulkFinder}
                  submitData={uploadBulkFinder2}
                  downloadFile={downloadBulkFinderFile}
                  {...props}
                  >
                  <Banner
                    text="Watch a short tutorial on how to use our bulk email finder"
                    link="https://www.youtube.com/embed/7cxvIs8qbVo"
                    buttonCTA="Watch (2 min)."
                  />
                </BulkFinder>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/email-verifier/single-verifier"
              component={(props) => (
                <SingleFinder
                  type="Single Email Verifier"
                  user={user}
                  title="Single Verifier"
                  data={SingleVerifierFormData}
                  getTableData={getSingleVerifierResults}
                  headCells={singleVerifierHeaderData}
                  deleteData={deleteSingleVerifierSearches}
                  formSubmit={verifySingleEmail}
                  submitData={uploadBulkFinder2}
                  {...props}
                >
                  <Banner
                    text="Watch a short tutorial on how to use our single email verifier"
                    link="https://www.youtube.com/embed/bIrtxCB5bsk"
                    buttonCTA="Watch (2 min)."
                  />
                </SingleFinder>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/email-verifier/bulk-verifier"
              component={(props) => (
                <BulkFinder
                  type="Bulk Upload Email Verifier"
                  user={user}
                  title="Bulk Verifier"
                  getTableData={getBulkVerifierResults}
                  userInputs={verifierUserInputs}
                  deleteData={deleteBulkVerifier}
                  uploadData={uploadBulkVerifier}
                  submitData={uploadBulkVerifier2}
                  downloadFile={downloadBulkVerifierFile}
                  {...props}
                  >
                  <Banner
                    text="Watch a short tutorial on how to use our bulk email verifier"
                    link="https://www.youtube.com/embed/oZiwgI3vtiY"
                    buttonCTA="Watch (2 min)."
                  />
                </BulkFinder>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/find-phone-number/single-finder"
              component={(props) => (
                <SingleFinder
                  type="Phone Number Finder"
                  user={user}
                  title="Phone Number Finder"
                  data={SinglePhoneNumberFormData}
                  getTableData={getSinglePhoneFinderResults}
                  // headCells={singleVerifierHeaderData}
                  headCells={singlePhoneNumberFinderHeaderData}
                  deleteData={deleteSinglePhoneNumberSearches}
                  formSubmit={findSinglePhoneNumber}
                  {...props}
                />
              )}
            ></ProtectedRoute>

            {/* <Account /> */}
            <ProtectedRoute
              exact
              path="/settings/account"
              component={(props) => (
                <Account {...props} user={user} title="Account" />
              )}
            />
            <ProtectedRoute
              exact
              path="/settings/buy-email-credits"
              component={(props) => (
                <EmailCredits
                  {...props}
                  user={user}
                  title="Buy Email Credits"
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/settings/plans"
              component={(props) => (
                <Plans {...props} user={user} title="Subscription Plans" />
              )}
            />
            <ProtectedRoute
              path="/settings/billing"
              component={(props) => (
                <ManageSubscription
                  {...props}
                  user={user}
                  title="Stripe Customer Portal"
                />
              )}
            />
            <ProtectedRoute
              path="/appsumo-ltd/redeem"
              component={(props) => (
                <AppsumoLTD
                  type="Redeem Appsumo LTD"
                  user={user}
                  title="Redeem Appsumo LTD"
                  {...appsumoLtdData}
                  {...props}
                />
              )}
            />
            <ProtectedRoute
              path="*"
              component={(props) => (
                <PageNotFound {...props} user={user} title="404 Not Found" />
              )}
            />
            {/* <PageNotFound /> */}
            {/* </ProtectedRoute> */}
            {/* </div>
            </div> */}
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default Router;
