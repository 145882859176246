// Data
import { bulkHeaderData } from "../data/emailOperations/bulkHeader";
import { singleFinderHeaderData } from "../data/emailOperations/singleFinderHeader";
import { singleVerifierHeaderData } from "../data/emailOperations/singleVerifierHeader";
import { apiUrl } from "../config.json";
// Services
import {
  getBulkFinderUploads,
  getBulkVerifierUploads,
  getSingleFinderSearches,
  getSingleVerifierSearches,
} from "../services/emailOperations";

// Utils
import { createBulkData, createSingleData } from "./formatData";

const apiEndpoint = apiUrl;

export async function getSingleFinderResults() {
  const data = await getSingleFinderSearches();
  const keys = singleFinderHeaderData.map((cell) => cell.id);
  const singleData = data.results.map((result) =>
    createSingleData(
      [
        `${result.name}`,
        result.domain_name,
        result.task_result.email,
        result.validity,
        result.taskid,
      ],
      keys
    )
  );
  return singleData;
}

export async function getSingleVerifierResults() {
  const data = await getSingleVerifierSearches();
  const keys = singleVerifierHeaderData.map((cell) => cell.id);
  const singleData = data.results.map((result) =>
    createSingleData([`${result.email}`, result.validity, result.taskid], keys)
  );

  return singleData;
}

export async function getBulkFinderResults() {
  const { uploaded_files: uploadedFiles } = await getBulkFinderUploads();
  const keys = bulkHeaderData.map((cell) => cell.id);
  const bulkData = uploadedFiles.map((rows) =>
    createBulkData(
      rows.concat(`${apiEndpoint}/download-file/bulk-finder/${rows[0]}`),
      keys
    )
  );

  return bulkData;
}

export async function getBulkVerifierResults() {
  const { uploaded_files: uploadedFiles } = await getBulkVerifierUploads();
  const keys = bulkHeaderData.map((cell) => cell.id);
  const bulkData = uploadedFiles.map((rows) =>
    createBulkData(
      rows.concat(`${apiEndpoint}/download-file/bulk-verifier/${rows[0]}`),
      keys
    )
  );

  return bulkData;
}
