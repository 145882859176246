import React from "react";

import UserMenu from "../components/DropdownProfile";
import { CircularProgress } from "@material-ui/core";

// Services
import userContext from "../services/userContext";
// import Drift from "react-driftjs";
// import Crisp from "react-crisp";

class Header extends React.Component {
  state = {
    userCredits: [],
  };
  componentDidMount = async () => {
    
    const user = await userContext.userContext();
    this.setState({ userCredits: user });
    this.userUpdate = setInterval(async () => {
      const user = await userContext.userContext();
      // console.log(user);
      this.setState({ userCredits: user });
    }, 15000);
    // const user = await userContext.userContext()
    console.log("Header Mount");
    try {
        global.$crisp.push(["set", "user:email", user?.email]);
        console.log("Crisp User set!");
    } catch (error) {
      console.log("Crisp user set error!");
    }
  };

  componentWillUnmount = () => {
    console.log("Header Unmount.");
    clearInterval(this.userUpdate);
  };

  render() {
    const { sidebarOpen, setSidebarOpen, user } = this.props;
    const { userCredits } = this.state;

    return (
      <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
        {/* <Drift
          appId="caahsnan8zti"
          // userId={user.email}
          attributes={{ email: user?.email, name: user?.name }}
        /> */}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Header: Left side */}
            <div className="flex">
              {/* Hamburger button */}
              <button
                className="text-gray-500 hover:text-gray-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
            </div>

            {/* Header: Right side */}
            <div className="flex items-center space-x-3">
              {/* <Notifications align="right" /> */}
              <h1 className="text-[6px] sm:text-xs md:text-xs lg:text-xs xl:text-xs text-gray-800">
                Email Credits:{" "}
                {userCredits.length === 0 ? (
                  <CircularProgress
                    className="ml-1"
                    style={{ width: 14, height: 14, color: "#000" }}
                  />
                ) : (
                  <span className="ml-1">{parseInt(userCredits?.email_credits).toLocaleString()}</span>
                )}
              </h1>
              <hr className="w-px h-6 bg-gray-200 mx-3" />
              <h1 className="text-[6px] sm:text-xs md:text-xs lg:text-xs xl:text-xs text-gray-800">
                Automation Credits:
                {userCredits.length === 0 ? (
                  <CircularProgress
                    className="ml-1"
                    style={{ width: 14, height: 14, color: "#000" }}
                  />
                ) : (
                  <span className="ml-1">{parseInt(userCredits?.credits_left).toLocaleString()}</span>
                )}
              </h1>
              {/* <Help align="right" /> */}
              {/*  Divider */}
              <hr className="w-px h-6 bg-gray-200 mx-3" />
              <UserMenu align="right" user={user} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

// function Header({ sidebarOpen, setSidebarOpen, user }) {
//   // const [searchModalOpen, setSearchModalOpen] = useState(false);

//   return (
//     <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
//       <div className="px-4 sm:px-6 lg:px-8">
//         <div className="flex items-center justify-between h-16 -mb-px">
//           {/* Header: Left side */}
//           <div className="flex">
//             {/* Hamburger button */}
//             <button
//               className="text-gray-500 hover:text-gray-600 lg:hidden"
//               aria-controls="sidebar"
//               aria-expanded={sidebarOpen}
//               onClick={() => setSidebarOpen(!sidebarOpen)}
//             >
//               <span className="sr-only">Open sidebar</span>
//               <svg
//                 className="w-6 h-6 fill-current"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <rect x="4" y="5" width="16" height="2" />
//                 <rect x="4" y="11" width="16" height="2" />
//                 <rect x="4" y="17" width="16" height="2" />
//               </svg>
//             </button>
//           </div>

//           {/* Header: Right side */}
//           <div className="flex items-center space-x-3">
//             {/* <button
//               className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ml-3 ${
//                 searchModalOpen && "bg-gray-200"
//               }`}
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSearchModalOpen(true);
//               }}
//               aria-controls="search-modal"
//             >
//               <span className="sr-only">Search</span>
//               <svg
//                 className="w-4 h-4"
//                 viewBox="0 0 16 16"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   className="fill-current text-gray-500"
//                   d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
//                 />
//                 <path
//                   className="fill-current text-gray-400"
//                   d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
//                 />
//               </svg>
//             </button>
//             <SearchModal
//               id="search-modal"
//               searchId="search"
//               modalOpen={searchModalOpen}
//               setModalOpen={setSearchModalOpen}
//             /> */}
//             {/* <Notifications align="right" /> */}
//             <h1 className="text-xs text-gray-800">Email Credits: 1000</h1>
//             <hr className="w-px h-6 bg-gray-200 mx-3" />
//             <h1 className="text-xs text-gray-800">Email Credits: 1000</h1>
//             {/* <Help align="right" /> */}
//             {/*  Divider */}
//             <hr className="w-px h-6 bg-gray-200 mx-3" />
//             <UserMenu align="right" user={user} />
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;
