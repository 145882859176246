import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import splitbee from "@splitbee/web";

import auth from "../services/authService";

class Logout extends Component {
    state = {}
    render() {
        splitbee.reset()
        auth.logout()
        this.props.updateUser()

        return (
            <Redirect to="/signin" />
        );
    }
}

export default Logout;