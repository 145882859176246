import React from "react";
import { Link } from "react-router-dom";

function CategoryCard({ items, category, subCategory, formType }) {
  return (
    <div className="col-span-full sm:col-span-4 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="flex flex-col h-full p-5">
        <header>
          <div className="flex items-center justify-between">
            {/* {categoryIcon(category)} */}
            <div className="flex flex-shrink-0 -space-x-3 -ml-px">
              {items.images.map((image, index) => {
                return (
                  <img
                    key={index}
                    className="border-2 border-white box-content"
                    src={`/static/assets/img/Module Tile Logos/${image}`}
                    width="28"
                    height="28"
                    alt={image}
                  />
                );
              })}
            </div>
          </div>
        </header>
        <div className="flex-grow mt-2">
          <Link
            className="inline-flex text-gray-800 hover:text-gray-900 mb-1"
            to={`/description/${category}/${subCategory}/${formType}`}
            // to={link}
          >
            <h2 className="text-xl font-semibold">{subCategory}</h2>
          </Link>
          <div className="text-sm">{items.description}</div>
        </div>
        <footer className="mt-5">
          <div className="flex justify-end">
            <div>
              <Link
                to={`/description/${category}/${subCategory}/${formType}`}
                className="text-sm inline-flex font-medium rounded-lg text-center px-2.5 py-1 border border-indigo-600 text-indigo-600 hover:border-indigo-500 hover:bg-indigo-500 hover:text-white shadow-brandShadow mr-2"
              >
                Documentation
              </Link>
            </div>
            <div>
              <Link
                className="text-sm inline-flex font-medium rounded-lg text-center px-2.5 py-1 border hover:bg-indigo-700 hover:border-indigo-700 border-indigo-600 bg-indigo-600 text-white shadow-brandShadow"
                to={`/config/${category}/${subCategory}/${formType}`}
              >
                Use this Module -&gt;
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CategoryCard;
