import PropTypes from "prop-types";
import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { CircularProgress } from "@material-ui/core";
import { loadStripe } from '@stripe/stripe-js';
import {stripe_key} from "../../data/stripeData/stripe_config_live.json";
import { apiUrl } from "../../config.json";
import http from "../../services/httpService";

const apiEndpoint = apiUrl;
const emailCreditPricing = 0.01;

const stripePromise = loadStripe(stripe_key);
const iOSBoxShadow = "0px 10px 20px 5px #3730A3";


const IOSSlider = styled(Slider)(() => ({
  color: "#4F46E5",
  height: 12,
  //   width: 500,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 36,
    width: 36,
    backgroundColor: "#4338CA",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 10px 20px 5px #3730A3,0px 10px 20px 5px #C7D2FE",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 10,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));


function IOSThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <ChevronLeftIcon className="w-4 text-white" />
      <ChevronRightIcon className="w-4 text-white" />
    </SliderThumb>
  );
}


IOSThumbComponent.propTypes = {
  children: PropTypes.node,
};


class SliderInput extends Component {
  state = {
    slider: {
      minValue: 500,
      maxValue: 25000,
      value: 1500,
      stepSize: 500,
    },
    loading: false,
  };

  componentDidMount() {
    const slider = this.state.slider;
    slider.percentage = (slider.value / slider.maxValue) * 100;
    slider.pricing = slider.value * emailCreditPricing;
    this.setState({ slider });
  }

  handleSubmit = async () => {
    this.setState({ loading: true })

    const stripe = await stripePromise;

    const response = await http.post(`${apiEndpoint}/stripe/buy-email-credits`,
        { "credits": this.state.slider.value }
    );

    console.log(response)
    // const session = await response.json();
    const session = response;
    await stripe.redirectToCheckout({
        sessionId: session.data.checkout_session_id,
    });

}


  handleChange = (event) => {
    const slider = this.state.slider;
    slider.value = event.target.value;
    slider.percentage = (slider.value / slider.maxValue) * 100;
    slider.pricing = slider.value * emailCreditPricing;
    this.setState({ slider });
    console.log(this.state.slider.value);
  };

  render() {
    const { slider, loading } = this.state;

    return (
      <div className="flex-grow">
        <div className="p-6">
          <div className="flex flex-row mb-4">
            <h2 className="text-2xl text-gray-800 font-bold">
              Buy Email Credits
            </h2>
            <div className="inline-flex items-center text-xs font-medium text-gray-100 bg-gray-700 rounded-full text-center px-2 my-1 mx-4">
              <svg
                className="w-3 h-3 flex-shrink-0 fill-current text-yellow-500 mr-1"
                viewBox="0 0 12 12"
              >
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              <span>Special Offer</span>
            </div>
          </div>

          <hr />
          <div className="flex flex-row justify-around">
            <h2 className="text-lg text-gray-800 font-medium my-5">
              {slider.value} Email Credits
            </h2>
            <h2 className="text-lg text-gray-800 font-medium my-5">
              ${slider.pricing}
            </h2>
          </div>
          <IOSSlider
            components={{ Thumb: IOSThumbComponent }}
            aria-label="ios slider"
            defaultValue={500}
            value={slider.value}
            onChange={this.handleChange}
            min={slider.minValue}
            max={slider.maxValue}
            step={slider.stepSize}
          />
          <footer className="mt-3 grid">
            <div className="justify-self-start">
              <button
                className="flex btn bg-indigo-600 hover:bg-indigo-700 text-white w-full disabled:cursor-not-allowed disabled:border-indigo-400 disabled:bg-indigo-400"
                onClick={this.handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    Wait...&nbsp;&nbsp;
                    <CircularProgress
                      className=""
                      style={{
                        width: 13,
                        height: 13,
                        color: "#fff",
                      }}
                    />
                  </>
                ) : (
                  <>Buy -&gt;</>
                )}
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default SliderInput;
