export const ConfigForms = {
  moduleName: {
    name: "id",
    label: "Module Name",
    placeholder: "Test 101",
    type: "text",
  },
  li_at: {
    name: "li_at",
    label: "Select LinkedIn Account to use",
    placeholder: "",
    type: "select",
  },
  linkedinSearchQuery: {
    name: "linkedin_search_link",
    label: "LinkedIn Search Query",
    placeholder: "https://www.linkedin.com/search...",
    type: "text",
  },
  salesnavSearch: {
    name: "sales_nav_search",
    label: "LinkedIn sales navigator lead search query URL",
    placeholder: "https://www.linkedin.com/sales/search...",
    type: "text",
  },
  googleSheetURL: {
    name: "gspread",
    label: "Google Sheet URL",
    placeholder: "https://docs.google.com/spreadsheets/d/...",
    type: "text",
  },
  googleSheetColumn: {
    name: "column_name",
    label: "Google Sheet Column Name",
    placeholder: "Column Name",
    type: "text",
  },
  salesnavLeadListName: {
    name: "list_name",
    label: "Sales Navigator Lead List Name",
    placeholder: "ABC...",
    type: "text",
  },
  salesnavAccountListName: {
    name: "list_name",
    label: "Sales Navigator Account List Name",
    placeholder: "ABC...",
    type: "text",
  },
  salesnavAccountSearchName: {
    name: "list_name",
    label: "Saved Account Search Name",
    placeholder: "ABC...",
    type: "text",
  },
  salesnavLeadSearchName: {
    name: "list_name",
    label: "Saved Lead Search Name",
    placeholder: "ABC...",
    type: "text",
  },
  linkedinGroup: {
    name: "linkedin_group_link",
    label: "Linkedin Group URL",
    placeholder: "https://www.linkedin.com/group...",
    type: "text",
  },
  limit: { name: "limit", label: "", placeholder: "200", type: "text" },
  enrichWorkEmail: {
    name: "find_email",
    label: "Enrich work emails? (One email enrichment uses one email credit)",
    options: ["Yes", "No"],
  },
  emailResults: {
    name: "email",
    label: "Email Address (result file will be sent to this)",
    placeholder: "friends@datachroma.com",
    type: "text",
  },
};
