import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/get-module-data/";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function getModuleData(id) {
    const { data: modules } = await http.get(apiEndpoint+id);
    return modules;
}

export default {
    getModuleData
};