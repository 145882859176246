import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TitleComponent } from "../../components/TitleComponent";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import MyDisclosure from "../../partials/disclosure";

// Data
import CategoryData from "../../data/modulesData/category.json";
import AppsumoBanner from "../../partials/appsumoBanner";

class Description extends Component {
  state = {
    sidebarOpen: false,
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  render() {
    const { user, formType, subCategory, category } = this.props.match.params;
    const { sidebarOpen } = this.state;
    const contextData = CategoryData[category]["subCategories"][subCategory];
    const cardsData = {
      "What does this module do?": "whatIsThisModule",
      Input: "input",
      Output: "output",
      "Steps to set up this module": "setup",
      "Tutorial Video": "video",
    };

    return (
      <>
        {/* <AppsumoBanner /> */}

        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            <div className="sticky top-16 z-20 bg-gray-100 flex flex-row sm:mb-0 justify-between px-4 sm:px-6 lg:px-8 pt-4 pb-4">
              <h1 className="self-start text-md sm:text-xl md:text-2xl text-gray-800 font-bold">
                {subCategory} ✨
              </h1>
              <Link
                className="text-sm text-center text-white inline-flex font-medium rounded-lg px-2.5 py-1 border shadow-brandShadow border-indigo-600 bg-indigo-600 hover:bg-indigo-700 hover:border-indigo-700"
                to={`/config/${category}/${subCategory}/${formType}`}
              >
                Use this Module -&gt;
              </Link>
            </div>

            <main>
              <TitleComponent title={`${subCategory} | Data Chroma`} />
              <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                {Object.keys(cardsData).map((key, index) =>
                  contextData.completeDescription[cardsData[key]] !== "" ? (
                    <MyDisclosure text={key} index={index}>
                      {key === "Input" || key === "Output" ? (
                        <div className="grid divide-y-2 items-center h-auto max-h-96 overflow-auto">
                          {contextData.completeDescription[cardsData[key]].map(
                            (inp, index) => (
                              <div key={index} className="py-3 text-sm">
                                {inp}
                              </div>
                            )
                          )}
                        </div>
                      ) : contextData.completeDescription[cardsData[key]] ? (
                        <div
                          className="py-3 h-auto max-h-96 overflow-auto"
                          dangerouslySetInnerHTML={{
                            __html:
                              contextData.completeDescription[cardsData[key]],
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </MyDisclosure>
                  ) : (
                    <></>
                  )
                )}
                {/* <div className="sticky top-16 z-50 bg-[#f1f5f9] pb-6 pt-2 flex flex-row sm:mb-0 justify-between">
                <h1 className="self-start text-sm mr-1 sm:text-xl md:text-2xl text-gray-800 font-bold">
                  {subCategory} ✨
                </h1>
                <button className="text-xs md:text-sm lg:text-sm text-center text-white font-medium rounded-lg px-2.5 py-1 border shadow-brandShadow border-indigo-600 bg-indigo-600 hover:bg-indigo-700 hover:border-indigo-700">
                  Use this Module -&gt;
                </button>
              </div> */}

                {/* {Object.keys(cardsData).map((key, index) =>
                  contextData.completeDescription[cardsData[key]] !== "" ? (
                    <div className="w-full mb-6" key={index}>
                      <div className="bg-white shadow-lg rounded-lg border border-gray-200 pb-3">
                        <div className="flex flex-row px-5 border-b bg-gray-50 py-2 rounded-t-lg justify-between">
                          <h2 className="font-semibold text-gray-800 text-sm py-1 self-start content-center">
                            {key}
                          </h2>
                        </div>
                        <div className="px-5 mb-1">
                          {key === "Input" || key === "Output" ? (
                            <div className="grid divide-y-2 items-center h-auto max-h-96 overflow-auto">
                              {contextData.completeDescription[
                                cardsData[key]
                              ].map((inp, index) => (
                                <div key={index} className="py-3 text-sm">
                                  {inp}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div
                              className="mt-2 h-auto max-h-96 overflow-auto"
                              dangerouslySetInnerHTML={{
                                __html:
                                  contextData.completeDescription[
                                    cardsData[key]
                                  ],
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )} */}

                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Description;
