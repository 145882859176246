// Data
import { singlePhoneNumberFinderHeaderData } from "../data/phoneNumberOperations/singlePhoneNumberFinderHeader";
// import { apiUrl } from "../config.json";
// Services
import { getSinglePhoneNumberSearches } from "../services/phoneNumberOperations";

// Utils
import { createSingleData } from "./formatData";


export async function getSinglePhoneFinderResults() {
  const data = await getSinglePhoneNumberSearches();
  const keys = singlePhoneNumberFinderHeaderData.map((cell) => cell.id);
  const singleData = data.results.map((result) =>
    createSingleData(
      [
        result.linkedin_url,
        result.phone_numbers,
        result.taskid,
      ],
      keys
    )
  );
  return singleData;
}
