import React from "react";
import { Link, Redirect } from "react-router-dom";
import Notification from "../components/notifications/notfy";
import { TitleComponent } from "../components/TitleComponent";

import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import TextInput from "../partials/inputs/text";

// Services
import auth from "../services/authService";

// Data
import { SignupForm } from "../data/formData/signup";
import { CircularProgress } from "@material-ui/core";
import AppsumoBanner from "../partials/appsumoBanner";

class Signup extends React.Component {
  state = {
    data: {
      full_name: "",
      email: "",
      password: "",
      password_repeat: "",
    },
    loading: false,
  };

  handleChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      await auth.register(this.state.data);
      this.props.updateUser();
      Notification("Signed-up successfully", "success");
      this.setState({ loading: false });
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response && ex.response.status === 406) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      if (ex.response && ex.response.status === 401) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
    }
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    if (user) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <main className="bg-white">
        {/* <AppsumoBanner /> */}
        <TitleComponent title={`${this.props.title} | Data Chroma`} />
        <div className="relative md:flex">
          {/* Content */}
          <div className="md:w-1/2">
            <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
              <div className="w-full">
                <h1 className="text-3xl text-gray-800 font-bold mb-6">
                  Create your Account ✨
                </h1>
                {/* Form */}
                <form onSubmit={this.handleSubmit}>
                  <div className="space-y-4">
                    {SignupForm.map((form, index) => (
                      <TextInput
                        key={index}
                        {...form}
                        required
                        onChange={this.handleChange}
                      />
                    ))}
                    {/* <div></div> */}
                    {/* <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="name"
                      >
                        Full Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="name"
                        className="form-input w-full"
                        type="text"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        autoComplete="on"
                      />
                    </div> */}
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">
                      {/* <label className="flex items-center">
                        <input type="checkbox" className="form-checkbox" />
                        <span className="text-sm ml-2">
                          Email me about product news.
                        </span>
                      </label> */}
                    </div>
                    <button
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap disabled:cursor-not-allowed disabled:bg-indigo-300 disabled:hover:bg-indigo-400 focus:outline-none"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          Signing-up&nbsp;&nbsp;&nbsp;
                          <CircularProgress
                            style={{ width: 13, height: 13, color: "#fff" }}
                          />
                        </>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </div>
                </form>
                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-gray-200">
                  <div className="text-sm">
                    Have an account?{" "}
                    <Link
                      className="font-medium text-indigo-500 hover:text-indigo-600"
                      to="/signin"
                    >
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image */}
          <div
            className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
            aria-hidden="true"
          >
            <img
              className="object-cover object-center w-full h-full"
              src={AuthImage}
              width="760"
              height="1024"
              alt="Authentication"
            />
            <img
              className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8"
              src={AuthDecoration}
              width="218"
              height="224"
              alt="Authentication decoration"
            />
          </div>
        </div>
      </main>
    );
  }
}

export default Signup;

// function Signup() {
//   const [data, setData] = useState({
//         full_name: "",
//         email: "",
//         password: "",
//         password_repeat: ""
//     })

//     return (
//   );
// }

// export default Signup;
