import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Notification from "../../components/notifications/notfy";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import BackdropLoader from "../loaders/backdropLoader";
import { CircularProgress } from "@material-ui/core";

const checkboxSx = {
  color: "#6366F1",
  "&.Mui-checked": {
    color: "#4F46E5",
  },
  "&.MuiCheckbox-indeterminate": {
    color: "#4F46E5",
  },
};

const selectedColor = "#E0E7FF"; //indigo-100

const selectedRowSx = {
  "&.Mui-selected": { background: selectedColor },
  "&.Mui-selected:hover": { background: "#EEF2FF" },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            sx={checkboxSx}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all rows",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span className="font-bold">{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleDelete, buttonLoading } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: selectedColor,
          //   (theme) =>
          //     alpha(
          //       theme.palette.primary.main,
          //       theme.palette.action.activatedOpacity
          //     ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Results
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          {buttonLoading ? (
            <CircularProgress
              className="mr-2"
              style={{ height: 37, width: 37, color: "#666a75" }}
            />
          ) : (
            <IconButton onClick={() => handleDelete()}>
              <DeleteIcon />
            </IconButton>
          )}
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  headCells,
  mainKey,
  emailField,
  getTableData,
  deleteData,
}) {
  //   console.log(mainKey);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setSingleData] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    // Anything in here is fired on component mount.
    async function getData(doLoad) {
      if (doLoad) {
        setLoading(true);
      }
      const singleData = await getTableData();
      console.log(singleData);
      setSingleData(singleData);
      if (doLoad) {
        setLoading(false);
      }
    }
    getData(true);
    const updateResults = setInterval(async () => {
      await getData(false);
    }, 3000);
    console.log("Table Mount");
    return () => {
      // Anything in here is fired on component unmount.
      console.log("Table Unmount.");
      clearInterval(updateResults);
    };
  }, []);

  const handleDelete = async () => {
    // function customFilter(row) {
    //   return !selected.includes(row.taskid);
    // }
    try {
      //   setScreenLoading(true);
      //   setLoading(true);
      setButtonLoading(true);
      setLoading(true);
      const response = await deleteData({ taskids: selected });
      Notification(response.status, "success");
      setSingleData(rows.filter((row) => !selected.includes(row.taskid)));
      setSelected([]);
      //   setLoading(false);
      //   setScreenLoading(false);
      setButtonLoading(false);
      setLoading(false);
    } catch (ex) {
      setButtonLoading(false);
      setLoading(false);
      //   setLoading(false);
      if (ex.response && ex.response.status === 401) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification("Something went wrong!", "error");
      return "";
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.taskid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <BackdropLoader open={screenLoading} text="Deleting..." />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDelete={handleDelete}
          buttonLoading={buttonLoading}
        />
        {loading ? (
          <div className="grid justify-items-center">
            <CircularProgress className="h-6 w-6 my-8" />
          </div>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="w-full"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.taskid);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          sx={selectedRowSx}
                          key={row.taskid}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              key={row.taskid}
                              onClick={(event) =>
                                handleClick(event, row.taskid)
                              }
                              //   color="primary"
                              sx={checkboxSx}
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>

                          {/* {Object.keys(row).map((key, index) => { */}
                          {headCells.map((cell, index) => {
                            const { id: key } = cell;
                            return (
                              <TableCell align="left" key={index}>
                                {key === emailField ? (
                                  <div>
                                    {row.validity.toLowerCase() !==
                                    "pending" ? (
                                      row[key] ? (
                                        <CopyToClipboard
                                          text={row[key]}
                                          onCopy={() =>
                                            Notification("Copied!", "success")
                                          }
                                        >
                                          <div className="flex flex-row justify-start cursor-pointer">
                                            <span className="place-self-start select-all">
                                              {row[key]}
                                            </span>

                                            <BadgeCheckIcon
                                              className={`ml-2 h-5 w-5 ${
                                                row.validity.toLowerCase() ===
                                                "correct"
                                                  ? "text-green-500"
                                                  : "text-yellow-400"
                                              }`}
                                            />
                                          </div>
                                        </CopyToClipboard>
                                      ) : (
                                        <>Not Found</>
                                      )
                                    ) : (
                                      <>Pending</>
                                    )}
                                  </div>
                                ) : (
                                  row[key]
                                )}
                              </TableCell>
                            );
                          })}
                          {/* <TableCell className="cursor-pointer" align="left">
                      <CopyToClipboard
                        text={row.name}
                        onCopy={() => Notification("Copied!", "success")}
                      >
                        <span> {row.name}</span>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </Box>
  );
}
