import React, { Component } from "react";
import { TitleComponent } from "../components/TitleComponent";
import SingleForm from "../partials/single/singleForm";
import Notification from "../components/notifications/notfy";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { redeemAppsumoLTD } from "../services/appsumo";
import AppsumoBanner from "../partials/appsumoBanner";

class AppsumoLTD extends Component {
  state = {
    sidebarOpen: false,
    loading: false,
    screenLoading: false,
    appsumoReddem: {
      code: "",
    },
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  handleChange = ({ currentTarget: input }) => {
    const { appsumoReddem } = this.state;
    appsumoReddem[input.name] = input.value;
    this.setState({ appsumoReddem });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const response = await redeemAppsumoLTD(this.state.appsumoReddem);
      Notification(response.status, "success");
      this.setState({
        loading: false,
        appsumoReddem: {
          code: "",
        },
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        Notification(ex.response.data.status, "error");
        this.setState({ loading: false });
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
      this.setState({ loading: false });
    }
  };

  render() {
    const { user, title, type } = this.props;
    const {loading, sidebarOpen } =
      this.state;
    return (
      <>
        {/* <AppsumoBanner /> */}
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={this.setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />
          <main>
            <TitleComponent title={`${title} | Data Chroma`} />
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    {type} ✨
                  </h1>
                </div>
              </div>

              <div className="grid grid-cols-18 gap-6">
                <SingleForm
                  {...this.props}
                  formTitle={type}
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}
                  loading={loading}
                />
              </div>
            </div>
          </main>
        </div>
      </div></>
    );
  }
}

export default AppsumoLTD;
