import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

function MyDisclosure({ children, text, index }) {
  console.log(text, index);
  return (
    <Disclosure
      as="div"
      className="shadow-lg rounded-lg mb-4"
    >
      {({ open }) => (
        <>
          <Disclosure.Button as={Fragment} className="cursor-pointer">
            <div className="w-full mb-6" key={index}>
              <div
                className={`flex flex-row px-5 border-b-[0.5px] bg-indigo-50 py-4 text-xl ${
                  open ? "rounded-t-lg" : "rounded-lg"
                } justify-between`}
              >
                <h2 className="font-semibold text-indigo-900 text-sm py-1 self-start content-center">
                  {text}
                </h2>
                <ChevronUpIcon
              className={`mt-1 ${
                open ? "" : "transform rotate-180"
              } w-5 h-5 text-purple-500`}
            />

              </div>
            </div>
            {/* </div> */}
          </Disclosure.Button>
          {/* <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          > */}
          <Disclosure.Panel as="div" className="bg-white rounded-b-lg">
            {/* <div className="bg-white shadow-lg rounded-b-lg"> */}
            <div className="px-5">{children}</div>
            {/* </div> */}
          </Disclosure.Panel>
          {/* </Transition> */}
        </>
      )}
    </Disclosure>
  );
}

export default MyDisclosure;
