import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";

function ModuleCard({ moduleData, handleDelete }) {
  const [removeLoading, setRemoveLoading] = useState(false);
  const typeColor = (type) => {
    switch (type) {
      // case "Not Started":
      // return "bg-gray-100 text-gray-500";
      case "Completed":
        return "bg-green-100 text-green-600";
      case "Stopped":
        return "bg-yellow-200 text-yellow-800";
      case "Running":
        return "bg-yellow-100 text-yellow-600";
      case "Failure":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-500";
    }
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          {moduleData.images.map((image, index) => (
            <img
              key={index}
              src={`/static/assets/img/Module Tile Logos/${image}`}
              width="32"
              height="32"
              alt="Icon 01"
            />
          ))}
        </header>
        <Link
          id={moduleData.id}
          key={moduleData.id}
          to={`/module/${moduleData.id}`}
          className="flex flex-col border-indigo-900	"
        >
          <Tooltip title={moduleData.id} followCursor>
            <h2 className="text-lg font-semibold text-gray-900 mb-2 truncate">
              {moduleData.id}
            </h2>
            {/* <div className="text-xs whitespace-nowrap">Just a tip</div> */}
          </Tooltip>
          <div className="text-xs font-semibold text-gray-400 mb-1">
            {moduleData.sub_category}
          </div>
          <div className="flex items-start mt-4">
            <div
              className={`text-xs font-semibold text-white px-2.5 py-0.5 rounded-full ${typeColor(
                moduleData.status
              )}`}
            >
              {moduleData.status}
            </div>
          </div>
        </Link>
        {/* <Link to={`/module/${moduleData.id}`} className="flex">
        </Link> */}
        {/* <Link to={`/module/${moduleData.id}`} className="flex">
        </Link> */}
        <footer className="mt-1 mb-4">
          <div className="flex justify-end items-center">
            <div>
              <button
                className="text-sm font-medium text-red-400 hover:text-red-600"
                disabled={removeLoading}
                onClick={async () => {
                  setRemoveLoading(true);
                  await handleDelete(moduleData.id);
                  setRemoveLoading(false);
                }}
              >
                {removeLoading ? (
                  <div className="flex-grow mr-8">
                    <CircularProgress
                      className="-mt-1"
                      style={{ width: 20, height: 20, color: "#F87171" }}
                    />
                  </div>
                ) : (
                  <>Remove -&gt;</>
                )}
              </button>{" "}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ModuleCard;
