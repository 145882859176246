export const singleFinderHeaderData = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    copyField: true,
  },
  {
    id: "domain",
    numeric: true,
    disablePadding: false,
    label: "Domain Name",
    copyField: true,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    copyField: true,
  },
  {
    id: "validity",
    numeric: true,
    disablePadding: false,
    label: "Validity",
    copyField: true,
  },
];
