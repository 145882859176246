import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/get-modules";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function getModules() {
    const { data: modules } = await http.get(apiEndpoint);
    return modules;
}

export default {
    getModules
};