export const singleVerifierHeaderData = [
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    copyField: true,
  },
  {
    id: "validity",
    numeric: true,
    disablePadding: false,
    label: "Validity",
    copyField: true,
  },
];
