import React from "react";
import { Link, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TitleComponent } from "../components/TitleComponent";

import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Notification from "../components/notifications/notfy";
import AppsumoBanner from "../partials/appsumoBanner";

// Services
import auth from "../services/authService";

class Signin extends React.Component {
  state = {
    data: {
      email: "",
      password: "",
    },
    loading: false,
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      await auth.login(this.state.data);
      this.props.updateUser();
      Notification("Logged in successfully", "success");
      this.setState({ loading: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        Notification(ex.response.data.status, "error");
        this.setState({ loading: false });
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
      return "";
    }
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    if (user) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <main className="bg-white">
        {/* <AppsumoBanner /> */}
        <TitleComponent title={`${this.props.title} | Data Chroma`} />
        <div className="relative md:flex">
          {/* Content */}
          <div className="md:w-1/2">
            <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
              <div className="w-full">
                <h1 className="text-3xl text-gray-800 font-bold mb-6">
                  Welcome back! ✨
                </h1>
                {/* Form */}
                <form onSubmit={this.handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email Address
                      </label>
                      <input
                        onChange={this.handleChange}
                        id="email"
                        className="form-input w-full focus:outline-none"
                        type="email"
                        name="email"
                        required
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        onChange={this.handleChange}
                        name="password"
                        id="password"
                        className="form-input w-full focus:outline-none"
                        type="password"
                        autoComplete="on"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">
                      <Link
                        className="text-sm underline hover:no-underline"
                        to="/reset-password"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <button
                      className={`btn text-white ml-3 disabled:cursor-not-allowed ${
                        loading
                          ? "bg-indigo-300 hover:bg-indigo-400"
                          : "bg-indigo-500 hover:bg-indigo-600"
                      }`}
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? (
                        <>
                          Signing In&nbsp;&nbsp;&nbsp;
                          <CircularProgress
                            style={{ width: 13, height: 13, color: "#fff" }}
                          />
                        </>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </form>
                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-gray-200">
                  <div className="text-sm">
                    Don’t you have an account?
                    <Link
                      className="font-medium text-indigo-500 hover:text-indigo-600"
                      to="/signup"
                    >
                      Sign Up
                    </Link>
                  </div>
                  {/* Warning */}
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <svg
                        className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span className="text-sm">
                        To support you during the pandemic some of the pro
                        features are free.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image */}
          <div
            className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
            aria-hidden="true"
          >
            <img
              className="object-cover object-center w-full h-full"
              src={AuthImage}
              width="760"
              height="1024"
              alt="Authentication"
            />
            <img
              className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8"
              src={AuthDecoration}
              width="218"
              height="224"
              alt="Authentication decoration"
            />
          </div>
        </div>
      </main>
    );
  }
}

export default Signin;

// function Signin() {
//   return (
//     <main className="bg-white">

//       <div className="relative md:flex">

//         {/* Content */}
//         <div className="md:w-1/2">
//           <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

//             <div className="w-full">
//               <h1 className="text-3xl text-gray-800 font-bold mb-6">Welcome back! ✨</h1>
//               {/* Form */}
//               <form>
//                 <div className="space-y-4">
//                   <div>
//                     <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address</label>
//                     <input id="email" className="form-input w-full" type="email" />
//                   </div>
//                   <div>
//                     <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
//                     <input id="password" className="form-input w-full" type="password" autoComplete="on" />
//                   </div>
//                 </div>
//                 <div className="flex items-center justify-between mt-6">
//                   <div className="mr-1">
//                     <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
//                   </div>
//                   <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3" to="/">Sign In</Link>
//                 </div>
//               </form>
//               {/* Footer */}
//               <div className="pt-5 mt-6 border-t border-gray-200">
//                 <div className="text-sm">
//                   Don’t you have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/signup">Sign Up</Link>
//                 </div>
//                 {/* Warning */}
//                 <div className="mt-5">
//                   <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
//                     <svg className="inline w-3 h-3 flex-shrink-0 fill-current mr-2" viewBox="0 0 12 12">
//                       <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
//                     </svg>
//                     <span className="text-sm">
//                       To support you during the pandemic super pro features are free until March 31st.
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>

//         {/* Image */}
//         <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
//           <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
//           <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
//         </div>

//       </div>

//     </main>
//   );
// }

// export default Signin;
