import React from "react";
// import { loadStripe } from "@stripe/stripe-js";

// Data
import stripe_data from "../../data/stripeData/stripe_config_live.json";
import pricing from "../../data/subscriptionPlansData/pricing.json";
import { apiUrl } from "../../config.json";

// Services
import http from "../../services/httpService";
import { CircularProgress } from "@material-ui/core";

// const stripePromise = loadStripe(stripe_data.stripe_key);
const apiEndpoint = apiUrl;

class PlansPanel extends React.Component {
  state = { annual: false, loading: {}, allDisabled: false };

  setAnnual = (value) => {
    this.setState({ annual: value });
  };

  handleClick = async ({ currentTarget: input }) => {
    const { annual, loading } = this.state;
    
    const priceId = stripe_data[`${input.id.toLowerCase()}${annual ? "-annual" : ""}`].id
    console.log(
      stripe_data[`${input.id.toLowerCase()}${annual ? "-annual" : ""}`]
    );
    loading[input.id] = true;
    // console.log(`${input.id.toLowerCase()}${annual ? "-annual" : ""}`);
    this.setState({ loading: loading, allDisabled: !this.state.allDisabled });
    const response = await http.post(
      `${apiEndpoint}/stripe/upgrade/${input.id}${annual ? "-annual" : ""}/${priceId}`
    );
    window.location = response.data.redirect_url;
    return;

    // const stripe = await stripePromise;
    // const response = await http.post(
    //   `${apiEndpoint}/stripe/upgrade/${input.id}${annual ? "-annual" : ""}`
    // );
    // const session = response;
    // await stripe.redirectToCheckout({
    //   sessionId: session.data.checkout_session_id,
    // });
  };

  render() {
    const { annual, loading, allDisabled } = this.state;
    const { user } = this.props;

    return (
      <div className="flex-grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Plans */}
          <section>
            <div className="mb-8">
              <div className="flex flex-row mb-4">
                <h2 className="text-2xl text-gray-800 font-bold">Plans</h2>
                <div className="inline-flex items-center text-xs font-medium text-gray-100 bg-gray-700 rounded-full text-center px-2 my-1 mx-4">
                  <svg
                    className="w-3 h-3 flex-shrink-0 fill-current text-yellow-500 mr-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                  </svg>
                  <span>Special Offer</span>
                </div>
              </div>
              {/* <h2 className="text-2xl text-gray-800 font-bold mb-4">
                Plans
                <div className="inline-flex items-center text-xs font-medium text-gray-100 bg-gray-700 rounded-full text-center px-2 py-0.5 ml-4">
                  <svg
                    className="w-3 h-3 flex-shrink-0 fill-current text-yellow-500 mr-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                  </svg>
                  <span>Special Offer</span>
                </div>x`
              </h2> */}
              <div className="text-sm">
                Your account is currently subscribed to
                <strong className="font-medium"> {user.plan_type}.</strong>
                {/* per month and will
            renew on <strong className="font-medium">July 9, 2021</strong>. */}
              </div>
            </div>

            {/* Pricing */}
            <div>
              {/* Toggle switch */}
              <div className="flex items-center space-x-3 mb-6">
                <div className="text-sm text-gray-500 font-medium">Monthly</div>
                <div className="form-switch focus-within:outline-blue">
                  <input
                    type="checkbox"
                    id="toggle"
                    className="sr-only"
                    checked={annual}
                    onChange={() => this.setAnnual(!annual)}
                  />
                  <label className="bg-gray-400" htmlFor="toggle">
                    <span
                      className="bg-white shadow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Pay annually</span>
                  </label>
                </div>
                <div className="text-sm text-gray-500 font-medium">
                  Annually &nbsp;
                  <div className="text-xs inline-flex font-medium bg-green-100 text-green-600 rounded-full text-center px-2.5 py-1">
                    4 Months Free  
                  </div>
                  {/* <span className="text-green-500">(-33%)</span> */}
                </div>
              </div>

              {/* Pricing tabs */}
              <div className="grid grid-cols-16 gap-1 sm:gap-y-8 md:gap-y-8">
                {/* Tab 3 */}
                {pricing.plans.map((plan, index) => (
                  <div
                    key={index}
                    className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200"
                  >
                    <div
                      className="absolute top-0 left-0 right-0 h-0.5 bg-indigo-500"
                      aria-hidden="true"
                    ></div>
                    <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                      <header className="flex items-center mb-2">
                        <div
                          className={`w-6 h-6 rounded-full flex-shrink-0 bg-gradient-to-tr mr-3 ${plan.color}`}
                        >
                          <svg
                            className="w-6 h-6 fill-current text-white"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                          </svg>
                        </div>
                        <h3 className="text-lg text-gray-800 font-semibold">
                          {plan.name}
                        </h3>
                      </header>
                      <div className="text-sm mb-2">
                        &nbsp;
                        {/* Ideal for individuals that need a custom solution with
                    custom tools. */}
                      </div>

                      {/* Price */}
                      <div className="text-gray-800 font-bold mb-4">
                        <span className="text-2xl">$</span>
                        <span className="text-3xl">
                          {annual ? plan.annualPrice : plan.price}
                        </span>
                        <span className="text-gray-500 font-medium text-sm">
                          /mo
                        </span>
                      </div>

                      {/* CTA */}
                      <button
                        className="btn bg-indigo-600 hover:bg-indigo-700 text-white w-full disabled:cursor-not-allowed disabled:border-indigo-400 disabled:bg-indigo-400"
                        id={plan.name}
                        onClick={this.handleClick}
                        disabled={loading[plan.name] || allDisabled}
                      >
                        {loading[plan.name] ? (
                          <>
                            Wait...&nbsp;&nbsp;
                            <CircularProgress
                              className=""
                              style={{
                                width: 13,
                                height: 13,
                                color: "#fff",
                              }}
                            />
                          </>
                        ) : (
                          "Upgrade"
                        )}
                      </button>
                    </div>
                    <div className="px-5 pt-4 pb-5">
                      <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
                        What's included
                      </div>
                      {/* List */}
                      <ul>
                        {plan.features.map((feature, index) => (
                          <li key={index} className="flex items-center py-1">
                            <svg
                              className="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                              viewBox="0 0 12 12"
                            >
                              <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div className="text-sm">{feature}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Contact Sales */}
          <section>
            <div className="px-5 py-3 bg-indigo-50 border border-indigo-100 rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center">
              <div className="text-gray-800 font-semibold mb-2 xl:mb-0">
                Looking for different configurations?
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:sales@datachroma.com"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                Contact Sales
              </a>
            </div>
          </section>

          {/* FAQs */}
          {/* <section>
            <div className="my-8">
              <h2 className="text-2xl text-gray-800 font-bold">FAQs</h2>
            </div>
            <ul className="space-y-5">
              <li>
                <div className="font-semibold text-gray-800 mb-1">
                  What is the difference between the three versions?
                </div>
                <div className="text-sm">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit.
                </div>
              </li>
              <li>
                <div className="font-semibold text-gray-800 mb-1">
                  Is there any difference between Basic and Plus licenses?
                </div>
                <div className="text-sm">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur.
                </div>
              </li>
              <li>
                <div className="font-semibold text-gray-800 mb-1">
                  Got more questions?
                </div>
                <div className="text-sm">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum in voluptate
                  velit esse cillum dolore eu fugiat{" "}
                  <a
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    href="#0"
                  >
                    contact us
                  </a>
                  .
                </div>
              </li>
            </ul>
          </section> */}
        </div>

        {/* Panel footer */}
        {/* <footer>
      <div className="flex flex-col px-6 py-5 border-t border-gray-200">
        <div className="flex self-end">
          <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">
            Cancel
          </button>
          <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">
            Save Changes
          </button>
        </div>
      </div>
    </footer> */}
      </div>
    );
  }
}

export default PlansPanel;
