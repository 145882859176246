import React, { Component } from "react";
import { apiUrl } from "../../config.json";
import BackdropLoader from "../../partials/loaders/backdropLoader";
import http from "../../services/httpService";

//Pages
import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import { TitleComponent } from "../../components/TitleComponent";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import AppsumoBanner from "../../partials/appsumoBanner";

// import { Redirect, Switch } from "react-router-dom";
// import stripe_data from "../../data/stripe_config_live.json"
// import { ThemeProvider } from 'react-bootstrap';
// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe(stripe_data.stripe_key);

const apiEndpoint = apiUrl;

class ManageSubscription extends Component {
  state = { portal_url: "", screnLoading: true, sidebarOpen: false };

  async componentDidMount() {
    const response = await http.get(
      `${apiEndpoint}/stripe/create-customer-portal-session`
    );
    console.log(response);
    this.setState({ portal_url: response.data.redirect_url });
    window.location = this.state.portal_url;
  }

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  render() {
    this.props.history.replace = "/";
    const { user } = this.props;
    const { sidebarOpen } = this.state;
    return (
      <>
      {/* <AppsumoBanner /> */}

      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={this.setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />
          <main>
            <TitleComponent title={this.props.title} />
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Account Settings ✨
                </h1>
              </div>

              {/* Content */}
              <div className="bg-white shadow-lg rounded-sm mb-8">
                <div className="flex flex-col md:flex-row md:-mr-px">
                  <SettingsSidebar />
                  <BackdropLoader
                    open={this.state.screnLoading}
                    text="Taking you to the Stripe Customer Portal ..."
                  />
                  {/* <BillingPanel /> */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div></>
    );
  }
}

export default ManageSubscription;
