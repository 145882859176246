import React from "react";
import CategoryCard from "../../partials/categoryCards/CategoryCard";

import { TitleComponent } from "../../components/TitleComponent";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";

// Data
import categoryData from "../../data/modulesData/category.json";
import AppsumoBanner from "../../partials/appsumoBanner";

class Modules extends React.Component {
  state = {
    sidebarOpen: false,
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  render() {
    const { user, title, category } = this.props;
    const { sidebarOpen } = this.state;

    return (
      <>
      {/* <AppsumoBanner /> */}

      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={this.setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />
          <main>
            <TitleComponent title={`${title} | Data Chroma`} />
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    Category - {category} ✨
                  </h1>
                </div>
              </div>

              {/* Cards */}
              <div className="grid grid-cols-8 gap-6">
                {Object.keys(categoryData[category]["subCategories"]).map(
                  (key, index) => (
                    <CategoryCard
                      key={index}
                      subCategory={key}
                      category={category}
                      items={categoryData[category]["subCategories"][key]}
                      formType={
                        categoryData[category]["subCategories"][key]["formType"]
                      }
                    />
                  )
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      </>
    );
  }
}

export default Modules;
