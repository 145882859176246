import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl;


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function getConnectedAccounts() {
    const { data: response } = await http.get(`${apiEndpoint}/get-connected-accounts`);
    return response;
}


export async function disconnectAccount(account_name) {
    const { data: response } = await http.get(`${apiEndpoint}/disconnect-account/${account_name}`);
    return response;
}

export async function addNewAccount(data) {
    const { data: response } = await http.post(`${apiEndpoint}/verify-and-add-account`,
        {...data});
    return response;
}

export async function modifyAccount(data) {
    const { data: response } = await http.post(`${apiEndpoint}/verify-and-modify-account`,
        {...data});
    return response;
}

export default {
    getConnectedAccounts,
    disconnectAccount,
    addNewAccount,
    modifyAccount
};
