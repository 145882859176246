import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../config.json";
import userContext from "./userContext";

const apiEndpoint = apiUrl + "/login";
const tokenKey = "token";

http.setJwt(getJwt());

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function login({ email, password }) {
    const { data: jwt } = await http.post(apiEndpoint, {
        email: email,
        password: password,
    });

    localStorage.setItem(tokenKey, jwt);
    http.setJwt(getJwt());
}

export async function register(user) {
    const apiEndpoint = apiUrl + "/register";
    const { data: jwt } = await http.post(apiEndpoint, {
        email: user.email,
        password: user.password,
        name: user.full_name,
    });
    localStorage.setItem(tokenKey, jwt);
    http.setJwt(getJwt());
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
}

export function isUserLoggedIn() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export async function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        const user = await userContext.userContext();
        return {...jwtDecode(jwt), ...user };
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt,
    isUserLoggedIn,
    register,
};