import React from "react";

const SelectInput = ({ name, label, onChange, required, options, value }) => {
  return (
    <div className="mb-2">
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label}
      </label>
      <select
        className="form-input w-full px-2 py-1 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
        onChange={onChange}
        id={name}
        name={name}
        required={required}
      >
        <option className="input-label-color" value="" selected>
          Please Select
        </option>

        {options.map((option, index) => (
          <option key={index} value={option} selected={value===option}>
            {option}
          </option>
        ))}
      </select>
      {/* <input
        name={name}
        required={required}
        placeholder={placeholder}
        defaultValue={value}
        disabled={value ? true: false}
      /> */}
    </div>
  );
};

export default SelectInput;
