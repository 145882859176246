import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/user-context";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function userContext() {
    const { data: user } = await http.get(apiEndpoint);
    return user;
}

export default {
    userContext
};