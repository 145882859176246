export const singlePhoneNumberFinderHeaderData = [
  {
    id: "linkedin_url",
    numeric: false,
    disablePadding: false,
    label: "LinkedIn URL",
    copyField: true,
  },
  {
    id: "phone_numbers",
    numeric: false,
    disablePadding: false,
    label: "Phone Numbers",
    copyField: true,
  },
];
