import http from "./httpService";
import { apiUrl } from "../config.json";
const apiEndpoint = apiUrl;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function findSingleEmail(data) {
    const { data: response } = await http.post(apiEndpoint+"/find-single-email", {...data});
    console.log(response)
    return response;
}

export async function getSingleFinderSearches() {
    const { data: response } = await http.get(apiEndpoint+"/get-single-finder-searches");
    return response;
}

export async function deleteSingleFinderSearches(data) {
    const { data: response } = await http.post(apiEndpoint+"/delete-single-finder-searches", {...data});
    return response;
}

export async function verifySingleEmail(data) {
    const { data: response } = await http.post(apiEndpoint+"/verify-single-email", {...data});
    console.log(response)
    return response;
}

export async function getSingleVerifierSearches() {
    const { data: response } = await http.get(apiEndpoint+"/get-single-verifier-searches");
    return response;
}

export async function deleteSingleVerifierSearches(data) {
    const { data: response } = await http.post(apiEndpoint+"/delete-single-verifier-searches", {...data});
    return response;
}

export async function getBulkFinderUploads() {
    const { data: response } = await http.get(apiEndpoint+"/get-bulk-finder-uploads");
    return response;
}

export async function uploadBulkFinder(data) {
    const { data: response } = await http.post(apiEndpoint+"/upload-bulk-finder", {...data});
    return response;
}

export async function uploadBulkFinder2(data) {
    const { data: response } = await http.post(apiEndpoint+"/upload-bulk-finder-2", {...data});
    console.log(response)
    return response;
}

export async function deleteBulkFinder(data) {
    const { data: response } = await http.post(apiEndpoint+"/delete-bulk-finder", {...data});
    console.log(response)
    return response;
}

export async function getBulkVerifierUploads() {
    const { data: response } = await http.get(apiEndpoint+"/get-bulk-verifier-uploads");
    return response;
}

export async function uploadBulkVerifier(data) {
    const { data: response } = await http.post(apiEndpoint+"/upload-bulk-verifier", {...data});
    return response;
}

export async function uploadBulkVerifier2(data) {
    const { data: response } = await http.post(apiEndpoint+"/upload-bulk-verifier-2", {...data});
    console.log(response)
    return response;
}

export async function deleteBulkVerifier(data) {
    const { data: response } = await http.post(apiEndpoint+"/delete-bulk-verifier", {...data});
    console.log(response)
    return response;
}


export default {
    findSingleEmail,
    getSingleFinderSearches,
    deleteSingleFinderSearches,
    verifySingleEmail,
    getSingleVerifierSearches,
    deleteSingleVerifierSearches,
    getBulkFinderUploads,
    uploadBulkFinder,
    uploadBulkFinder2,
    deleteBulkFinder,
    getBulkVerifierUploads,
    uploadBulkVerifier,
    uploadBulkVerifier2,
    deleteBulkVerifier,
};