import React, { useState } from "react";

import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import { TitleComponent } from "../../components/TitleComponent";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SliderInput from "../../partials/inputs/slider";
import AppsumoBanner from "../../partials/appsumoBanner";

function EmailCredits(props) {
  const { user, title } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
    {/* <AppsumoBanner /> */}

    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
        {/* <SliderInput /> */}

          <TitleComponent title={title} />
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                Account Settings ✨
              </h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />
                <SliderInput/>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div></>
  );
}

export default EmailCredits;
