// import LottieLoader from "../../partials/loaders/lottieLoader";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const BackdropLoader = ({open, text}) => {

  return (
    <Backdrop
      // sx={{ color: "#6366F1", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      sx={{
        opacity: "0.1",
        backgroundColor: "rgba(0,0,0,0.35)",
        color: "#6366F1",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      {/* <LottieLoader /> */}
      <div className="grid grid-cols-1 grid-rows-1">
        <div className="flex justify-center">
          <CircularProgress />
        </div>
        <div className="mt-4">
          <h1 className="text-xl text-gray-50 font-semibold">{text}</h1>
        </div>
      </div>
    </Backdrop>
  );
};

export default BackdropLoader;
