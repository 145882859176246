import React from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import ModuleCard from "../partials/dashboard/ModuleCard";
import getModules from "../services/getModules";
import moduleOperations from "../services/moduleOperations";
import Notification from "../components/notifications/notfy";
import BackdropLoader from "../partials/loaders/backdropLoader";
import { SplitbeeAnalytics } from "@splitbee/node";

import { TitleComponent } from "../components/TitleComponent";

// Services
import auth from "../services/authService";
import AppsumoBanner from "../partials/appsumoBanner";

class Dashboard extends React.Component {
  state = {
    prevModules: [],
    modules: [],
    sidebarOpen: false,
    screenLoading: false,
    removeLoading: false,
  };

  componentDidMount() {
    this.getModules();
    console.log("Dashboard Mount!");
  }

  getModules = async () => {
    this.setState({ screenLoading: true });
    // return "";
    try {
      const modules = await getModules.getModules();
      this.setState({ modules: modules.data, prevModules: modules.data });
    } catch (e) {
      Notification("Some error. Please try Again in some time", "error");
      // notyf.error("Some error. Please try Again in some time");
    }
    this.setState({ screenLoading: false });
  };

  handleDelete = async (id) => {
    console.log("Handle Delete");
    console.log(id);
    // return "";

    try {
      await moduleOperations.deleteModule(id);
      const { modules } = this.state;
      const modifiedModules = modules.filter((module) => module.id !== id);
      this.setState({ modules: modifiedModules });
    } catch (ex) {
      if (ex.response && ex.response.status === 300) {
        Notification(ex.response.data.status, "error");
      } else {
        Notification("Some error. Please try Again in some time", "error");
      }
    }
    // this.getModules();
  };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  handleChange = (e) => {
    // e.prevetDefault()
    const { name, value } = e.target;
    let { prevModules } = this.state;
    let filteredModules = prevModules.filter((module) =>
      module.id.toLowerCase().includes(value)
    );
    this.setState({ modules: filteredModules });
    console.log(filteredModules);
  };

  render() {
    const { user, title } = this.props;
    const { modules, prevModules, sidebarOpen, screenLoading } = this.state;
    const analytics = new SplitbeeAnalytics("7QJJAL050QXB");
    analytics.user.set({
      email: auth.isUserLoggedIn().email,
    });

    return (
      <>
        {/* <AppsumoBanner /> */}
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            <main>
              <TitleComponent title={`${title} | Data Chroma`} />

              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Welcome banner */}
                <WelcomeBanner user_name={user.name} />

                {screenLoading ? (
                  <BackdropLoader open={screenLoading} text="Loading...." />
                ) : // {/* Cards */}
                prevModules.length > 0 ? (
                  <>
                    <div className="sm:flex sm:justify-between sm:items-center mb-4">
                      <h1>
                        Module Slots ({prevModules.length}/{user.module_limit})
                      </h1>
                {/* Search form */}
                <div className="max-w-xl mb-5 mt-7">
                  <form className="relative">
                    <label htmlFor="app-search" className="sr-only">
                      Search
                    </label>
                    <input
                      id="module-search"
                      className="form-input w-full pl-9 py-3 focus:border-slate-300"
                      type="search"
                      placeholder="Search…"
                      onChange={(e) => this.handleChange(e)}
                    />
                    <button
                      className="absolute inset-0 right-auto group"
                      type="submit"
                      aria-label="Search"
                    >
                      <svg
                        className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                      </svg>
                    </button>
                  </form>
                </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                      {modules.map((moduleData, index) => (
                        <ModuleCard
                          key={index}
                          moduleData={moduleData}
                          handleDelete={this.handleDelete}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
