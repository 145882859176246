import React from "react";
import { TitleComponent } from "../../../components/TitleComponent";
import SingleForm from "../../../partials/single/singleForm";
import Header from "../../../partials/Header";
import Sidebar from "../../../partials/Sidebar";
import EnhancedTable from "../../../partials/bulkResults/otherTable";
import Notification from "../../../components/notifications/notfy";
import AppsumoBanner from "../../../partials/appsumoBanner";

const mainKey = "name";
const emailField = "email";

class SingleFinder extends React.Component {
  state = {
    loading: false,
    formData: { first_name: "123123" },
    sidebarOpen: false,
    singleData: [],
  };

  // getSingleResults=async()=>{
  //   const data = await getSingleFinderSearches();
  //   const keys = singleFinderHeaderData.map((cell) => cell.id);
  //   const singleData = data.results.map((result) =>
  //     createData(
  //       [
  //         `${result.first_name} ${result.last_name}`,
  //         result.domain_name,
  //         result.task_result.email,
  //         result.validity,
  //         result.taskid,
  //       ],
  //       keys
  //     )
  //   );
  //   // console.log(singleData);
  //   this.setState({singleData: singleData})
  // }
  // componentDidMount = async () => {
  //   await this.props.getTableData();
  //   this.updateResults = setInterval(async () => {
  //     await this.props.getTableData();
  //   }, 3000);
  //   console.log("Table Mount");
  // };

  // // componentWillUnmount = () => {
  // //   console.log("Header Unmount.");
  // //   clearInterval(this.updateResults);
  // // };

  setSidebarOpen = () => {
    const sidebarOpen = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    console.log("Form Submitted");
    try {
      await this.props.formSubmit(this.state.formData);
      this.setState({ loading: false });
      this.setState({ formData: {} });
    } catch (ex) {
      this.setState({ loading: false });
      this.setState({ formData: {} });
      if (ex.response && ex.response.status === 406) {
        Notification(ex.response.data.status, "error");
        return "";
      }
      Notification(
        "Something went wrong! Please try again in some time.",
        "error"
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const { formData } = this.state;
    formData[input.name] = input.value;
    this.setState({ formData });
  };

  handleSelectedItems = (selectedItems) => {
    // const selectedItems = this.state.selectedItems;
    this.setState({ selectedItems: [...selectedItems] });
  };

  render() {
    const { user, data, getTableData, headCells, deleteData, children } = this.props;
    const { sidebarOpen, formData } = this.state;

    return (
      <>
        {/* <AppsumoBanner /> */}

        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            user={user}
          />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              user={user}
            />
            {children}
            <main>
              <TitleComponent title={`${this.props.title} | Data Chroma`} />
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      {this.props.type} ✨
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-20 gap-3">
                  <div className="flex flex-col col-span-full sm:col-span-6">
                    <SingleForm
                      {...data}
                      formData={formData}
                      formTitle={this.props.type}
                      onChange={this.handleChange}
                      loading={this.state.loading}
                      onSubmit={this.handleSubmit}
                    />
                  </div>
                  <div className="flex flex-col col-span-full sm:col-span-14">
                    {/* <StickyHeadTable /> */}
                    <EnhancedTable
                      headCells={headCells}
                      mainKey={mainKey}
                      emailField={emailField}
                      getTableData={getTableData}
                      deleteData={deleteData}
                    />
                    {/* <TestTable /> */}
                    {/* <BulkTable selectedItems={this.handleSelectedItems} /> */}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default SingleFinder;
