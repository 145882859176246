import React, { Component } from "react";
import TextInput from "../inputs/text";
import CircularProgress from "@material-ui/core/CircularProgress";

class SingleForm extends Component {
  state = { loading: false };

  render() {
    const { formTitle, inputs, onSubmit, onChange, loading, loadingText, cta } =
      this.props;
    return (
      <div className="col-span-16 sm:col-span-16 md:col-span-6 lg:col-span-8 tablet:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200 place-self-start w-full">
        {/* <header className="px-3 p-5 border-b bg-grey-100"> */}
        <div className="flex flex-col px-5 border-b bg-gray-50 py-2 rounded-t-lg">
          <h2 className="font-semibold text-gray-800 text-sm py-1">
            {formTitle}
          </h2>
        </div>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col px-5 mb-4">
            <div className="flex-grow mt-2">
              {inputs.map((input, index) => {
                // console.log(formData[input.name])
                return (
                  <TextInput
                    key={index}
                    onChange={onChange}
                    {...input}
                    required
                    // value={formData[input.name]}
                  />
                );
              })}
            </div>

            <footer className="mt-3 grid">
              <div className="justify-self-center">
                <button
                  className="text-sm inline-flex font-medium rounded-lg text-center px-2.5 py-1 border text-white shadow-brandShadow disabled:cursor-not-allowed disabled:border-indigo-400 disabled:bg-indigo-400 border-indigo-600 bg-indigo-600 hover:border-indigo-700 hover:bg-indigo-700"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      {loadingText}&nbsp;&nbsp;
                      <CircularProgress
                        className="mt-1"
                        style={{ width: 13, height: 13, color: "#fff" }}
                      />
                    </>
                  ) : (
                    cta
                  )}
                </button>
              </div>
            </footer>
          </div>
        </form>
      </div>
    );
  }
}

export default SingleForm;
