export function createSingleData(rows, keys) {
  const dict = {};
  keys.map((key, index)=> dict[key]=rows[index])
  dict.taskid = rows.slice(keys.length, keys.length+1)[0]
  // console.log(rows.slice(keys.length, keys.length+1)[0])
  return dict;
}

export function createBulkData(rows, keys) {
  const dict = {};
  keys.map((key, index) => (dict[key] = rows[index]));
  return dict;
}