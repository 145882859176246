export const SignupForm = [
  {
    name: "email",
    label: "Email",
    placeholder: "friends@datachroma.com",
    type: "text",
  },
  {
    name: "full_name",
    label: "Full Name",
    placeholder: "John Doe",
    type: "text",
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
  },
  {
    name: "repeat_password",
    label: "Repeat Password",
    placeholder: "Repeat Password",
    type: "password",
  },
];
